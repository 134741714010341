import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: "Unit 6",
    id: "WB7-U6-P41-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 25 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page41/E1/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "eat",
        "listen",
        "<s>must</s>",
        "mustn’t",
        "should ",
        "shouldn’t",
        "sit",
      ],
      width: "620px",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page41/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 89px; left: 350px; "><input id='0' /></div>
              <div style=" position: absolute; top: 267px; left: 147px; "><input id='1' /></div>
              <div style=" position: absolute; top: 253px; left: 340px; "><input id='2' /></div>
              <div style=" position: absolute; top: 433px; left: 147px; "><input id='3' /></div>
              <div style=" position: absolute; top: 534px; left: 147px; "><input id='4' /></div>
              <div style=" position: absolute; top: 518px; left: 338px; "><input id='5' /></div>
            </div>
          </div>
        `,
        answer: [
          "listen",
          "mustn't",
          "eat",
          "should",
          "shouldn't",
          "sit",
        ]
      }
    ],
  },
  2: {
    unit: "Unit 6",
    id: "WB7-U6-P41-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page41/E2/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          " Complete the sentences with must, mustn’t, should or shouldn’t.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page41/E2/1.jpg' /></div>
            <div>
             <div style=" position: absolute; top: 227px; left: 94px; "><input id='0' /></div>
              <div style=" position: absolute; top: 277px; left: 48px; "><input id='1' /></div>
              <div style=" position: absolute; top: 327px; left: 253px; "><input id='2' /></div>
              <div style=" position: absolute; top: 377px; left: 48px; "><input id='3' /></div>
              <div style=" position: absolute; top: 427px; left: 48px; "><input id='4' /></div>
              <div style=" position: absolute; top: 477px; left: 245px; "><input id='5' /></div>
              <div style=" position: absolute; top: 527px; left: 83px; "><input id='6' /></div>
              <div style=" position: absolute; top: 602px; left: 16px; "><input id='7' /></div>

            </div>
          </div>
        `,
        answer: [
          "must",
          "shouldn't",
          "must",
          "mustn't",
          "should",
          "mustn't",
          "should",
          "must",
        ]
      }
    ],
  },
  3: {
    unit: "Unit 6",
    id: "WB7-U6-P41-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 18, textAlign: 'left' },
    inputSize: 300,
    exerciseKey: "img/FriendsPlus/Page41/E3/Key/answerKey.png",
    maxLength: 100,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title:
          " Complete the sentences with <i>must, mustn't, should</i> or <i>shouldn't</i>.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative; margin: 10px">
            <div style="display: flex; justify-content: center;"><img src='img/FriendsPlus/Page41/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 247px; left: 67px; "><input id='0' /></div>
              <div style=" position: absolute; top: 299px; left: 67px; "><input id='1' /></div>
              <div style=" position: absolute; top: 351px; left: 67px; "><input id='2' /></div>
              <div style=" position: absolute; top: 403px; left: 67px; "><input id='3' /></div>
              <div style=" position: absolute; top: 455px; left: 67px; "><input id='4' /></div>
              <div style=" position: absolute; top: 507px; left: 67px; "><input id='5' /></div>
              <div style=" position: absolute; top: 581px; left: 67px; "><input id='6' /></div>
              <div style=" position: absolute; top: 633px; left: 67px; "><input id='7' /></div>
            </div>
          </div>
        `,
        answer: [
          "should bring a first-aid kit.",
          "mustn't eat the fruit on the trees.",
          "must bring a sleeping bag.",
          "shouldn't wear new clothes.",
          "mustn't swim in the river.",
          "should carry a water bottle.",
          "should be quiet at night.",
          "shouldn't bring a mobile phone.",
        ]
      }
    ],
  },
  4: {
    unit: "Unit 6",
    id: "WB7-U6-P41-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [{
      src: [
        "cinema",
        "football stadium",
        "library",
        "park",
        "skate park",
        "tennis club",
        "theatre",

      ],
      width: "100%",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          "Think of a place in your town. Write some rules and advice for this place.Use exercise 3 to help you. Choose one of the places in the box.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div><textarea id="0" rows="11">I get up slowly in the morning because I'm tired. </textarea></div>
        `,
        answer: [],
      },
    ],
  },

}

export default json;
