import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB7-U2-P14-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page14/E1/Key/answerKey.png",
    inputSize: 40,
    isHiddenCheck: true,
    stylesTextInput: { fontSize: 25 },
    titleQuestion: [
      {
        num: "1",
        title: " Look at the pictures. Correct the words.",
        color: "#1d71a9",
        star: 1,
      },
    ],
    questions: [
      {
        title: ` 
          <div style="display: flex">
            <div><img src='img/FriendsPlus/Page14/E1/1.jpg' /></div>
            <div>
              <tr>
                <td style="padding: 20px"><s>letter</s></td>
                <td><u style="color: grey">e m a i l</u></td>
              </tr>
              <tr>
                <td style="padding: 20px"><b>1</b> landline</td>
                <td># # b # # # &nbsp;# # # n #</td>
              </tr>
              <tr>
                <td style="padding: 20px"><b>2</b>  email</td>
                <td># # r #</td>
              </tr>
              <tr>
                <td style="padding: 20px"><b>3</b> mobile phone</td>
                <td># # # d # # n # </td>
              </tr>
              <tr>
                <td style="padding: 20px"><b>4</b> emoji</td>
                <td># # # t # #</td>
              </tr>
              <tr>
                <td style="padding: 20px"><b>5</b> card</td>
                <td> # # # # i</td>
              </tr>
            </div>
          </div>
        `,
        answer: [
          "m",
          "o",
          "i",
          "l",
          "e",
          "p",
          "h",
          "o",
          "e",
          "c",
          "a",
          "d",
          "l",
          "a",
          "n",
          "l",
          "i",
          "e",
          "l",
          "e",
          "t",
          "e",
          "r",
          "e",
          "m",
          "o",
          "j",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB7-U2-P14-E4",
    component: T6,
    inputSize: "220px",
    exerciseKey: "img/FriendsPlus/Page14/E2/Key/answerKey.png",

    maxLength: 13,
    hintBox: [
      {
        src: [
          "conversations",
          "emoticons",
          "instant",
          "<s>mobile</s> ",
          "social",
          "text",
          "videos",
        ],
        borderColor: "#1d71a9",
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: "Complete the sentences with the words.",
        color: "#1d71a9",
        star: 2,
      },
    ],
    stylesTextInput: { textAlign: "center" },
    questions: [
      {
        title: `
        <div>
          <div>
            <div style="display: flex; justify-content: center">
              <hintbox id="0"></hintbox>
            </div>
            <div>
            <div style="color: grey;">We haven’t got a landline at home. I just use my <b>mobile</b  > phone</div>
            <div><b>1</b>. I don’t write a lot of # messages to my friends. I only send one or two every day.</div>
            <div><b>2</b>. My sister often has long  # on the phone with her best friend..</div>
            <div><b>3</b>. ‘Do you use # chat to talk to your aunt and uncle in Australia?’ ‘Yes, we use Skype.’</div>
            <div><b>4</b>. I like using funny symbols called  # when I text my friends. This one is my favourite! </div>
            <div><b>5</b>. Hakan uses # messaging with his school friends.</div>
            <div><b>6</b>. ‘Does your brother go on # media every day?’ ‘Yes, he’s on Facebook every evening.’</div>
            </div>
          </div>
        </div>`,
        answer: [
          "text",
          "conversations",
          "video",
          "emoticons",
          "instant",
          "instant",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB7-U2-P14-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page14/E3/Key/answerKey.png",
    inputSize: 120,
    stylesTextInput: { fontSize: 25 },
    titleQuestion: [
      {
        num: "3",
        title: " Complete the word on the text.",
        color: "#1d71a9",
        star: 2,
      },
    ],
    questions: [
      {
        title: ` 
          <div>
            <div style="text-align: center"><img style="width:50%" src='img/FriendsPlus/Page14/E3/1.jpg' /></div>
            <div>
              <tr>
                <td style="padding: 30px"><img style="width:200%"  src='img/FriendsPlus/Page14/E3/2.jpg' /></td>
                <td style="vertical-align: top; padding: 40px">I sometimes say ‘happy birthday’ to my friends on social media  , but I never send birthday <b style="display: inline-block">1. c #</b> – they’re very expensive! <b style="display: inline-block"> Noah, 12</b></td>
              </tr>
              <tr>
                <td style="padding: 30px"><img style="width:200%" src='img/FriendsPlus/Page14/E3/3.jpg' /></td>
                <td style="vertical-align: top; padding: 40px">I send my friends a ‘happy birthday’ text <b style="display: inline-block">2. m #</b> with a smiley <b style="display: inline-block">3. e #</b> <b style="display: inline-block">n </b> in it. <b style="display: inline-block"> Alice, 14</b></td>
              </tr>
              <tr>
                <td style="padding: 30px"><img style="width:200%" src='img/FriendsPlus/Page14/E3/4.jpg' /></td>
                <td style="vertical-align: top; padding: 40px">My sister and I always call our grandmother on her <b style="display: inline-block">4. l #</b> when it’s her birthday. She hasn’t got a mobile <b style="display: inline-block">5. p #</b> . We sometimes write a long <b style="display: inline-block">6. l #</b> to her, too, and send it to her by post. <b style="display: inline-block"> Daniella, 15</b></td>
              </tr>
              <tr>
                <td style="padding: 30px"><img style="width:200%" src='img/FriendsPlus/Page14/E3/5.jpg' /></td>
                <td style="vertical-align: top; padding: 40px">I’ve got a lot of family in India. When it’s my cousin’s birthday, I usually video <b style="display: inline-block">7. c #</b> with him. We sometimes use instant <b style="display: inline-block">8. m #</b> , too. <b style="display: inline-block"> Arjun, 13</b></td>
              </tr>
            </div>
          </div>
        `,
        answer: [
          "ard",
          "essage",
          "moticon",
          "andline",
          "hone",
          "etter",
          "hat",
          "essaging",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB7-U2-P14-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [
      {
        src: ["always", "never", "often", "sometimes"],
        borderColor: "#1d71a9",
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title:
          "How do you communicate with your friends and family in these situations? Write sentences using communication words and your own ideas.",
        color: "#1d71a9",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div style= "padding: 10px;"><hintbox id="0"></hintbox></div>
              <div>Your grandfather isn’t very well.<u style="text-decoration: dotted underline;">&emsp;<i><b>I sometimes call him on his landline.</b></i>&emsp;</u></div>
              <div style="display: flex"><b style="padding-top: 6px;">1</b><textarea id="0" rows="2" >Your friends aren’t at school. </textarea></div>
              <div style="display: flex; margin-top: -35px"><b style="padding-top: 6px;">2</b><textarea id="1" rows="2" >Your brother or sister passes an exam. </textarea></div>
              <div style="display: flex; margin-top: -35px"><b style="padding-top: 6px;">3</b><textarea id="2" rows="2" >Your brother, sister or school friend is late for something important.  </textarea></div>
              <div style="display: flex; margin-top: -35px"><b style="padding-top: 6px;">4</b><textarea id="3" rows="2" >You want to chat to your friend in a different country. </textarea></div>
              <div style="display: flex; margin-top: -35px"><b style="padding-top: 6px;">5</b><textarea id="4" rows="2" >You want to speak to your grandparents. </textarea></div>
              <div style="display: flex; margin-top: -35px"><b style="padding-top: 6px;">6</b><textarea id="5" rows="2" >You want to tell your friends about your holiday. </textarea></div>
            </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
