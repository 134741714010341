import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 8",
    id: "WB7-U8-P52-E1",
    component: T6,
    questionImage: [],
    inputSize: 345,
    exerciseKey: "img/FriendsPlus/Page52/E1/Key/answerKey.png",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the words. Then match clues a–f with words 1–6.",
        color: "rgb(245, 128, 36)",
        star: 1
      },
    ],
    questions: [
      {
        title: `
        <div><b>a &emsp;</b>Planes take off and land on it.</div>
        <div><b>b &emsp;</b>You get on planes there.</div>
        <div><b>c &emsp;</b>You watch it and know when a flight departs.</div>
        <div><b>d &emsp;</b>You look at it to look for a place in an airport.</div>
        <div><b>e &emsp;</b>You take it to move between terminals.</div>
        <div><b>f &emsp;</b>You arrive in and leave an airport there.</div>
        <br/>
        <div style="display:flex; gap:10px; width:1000px;">
          <div style="flex:3;">
            <div><b>1 &emsp;</b>t_rm_n_l</div>
            <div><b>2 &emsp;</b>bo_rd_n_ &ensp; g_t_</div>
            <div><b>3 &emsp;</b>sh_ttl_</div>
            <div><b>4 &emsp;</b>d_p_rt_r_ &ensp; i_f_rma_i_n &ensp; b_a_d</div>
            <div><b>5 &emsp;</b>y_l_o_ &ensp; s_gn</div>
            <div><b>6 &emsp;</b>r<b><u>u</u></b>nv<b><u>a</u></b>y</div>
          </div>
          <div style="flex:0;">
            <div><b>🠪</b></div>
            <div><b>🠪</b></div>
            <div><b>🠪</b></div>
            <div><b>🠪</b></div>
            <div><b>🠪</b></div>
          </div>
          <div style="flex:3;">
            <div><b>#</b></div>
            <div><b>#</b></div>
            <div><b>#</b></div>
            <div><b>#</b></div>
            <div><b>#</b></div>
          </div>
          <div style="flex:1;">
            <div><input width="50px"</div>
            <div><input width="50px"</div>
            <div><input width="50px"</div>
            <div><input width="50px"</div>
            <div><input width="50px"</div>
            <div>&emsp;<b><u>a</u></b></div>
          </div>
        </div>
          `,
        answer: [
          "terminal ",
          "boarding gate",
          "shuttle",
          "departure information board",
          "yellow sign ",
          "f", "b", "e", "c", "d",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "WB7-U8-P52-E2",
    component: T6,
    questionImage: [],
    inputSize: 325,
    exerciseKey: "img/FriendsPlus/Page52/E2/Key/answerKey.png",

    stylesTextInput: { marginInline: '10px', fontSize: 24 },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title:
          " Fill in each blank with ONE word.",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> BL7050 has just arrived. The plane is still on the <b>r</b> # Keep your seatbelts fastened.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> The <b>d</b> # says our flight takes off at 12:30. We have one hour left.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Look at the <b>y</b> # . The prayer room is over there, on the left.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Our plane has stopped at <b>t</b> # IV, Changi Airport.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> We must take this <b>s</b> # and move to terminal 2. The subway is there.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Passengers flying to Đà Nẵng on VN2080, please go to <b>b</b> # 05.</div></div>
              </div>
          `,
        answer: [
          "runway ",
          "departure information board ",
          "yellow sign ",
          "terminal ",
          "shuttle ",
          "oarding gate",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 5",
    id: "WB7-U5-P52-E3",
    audio: "Audios/1-08 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 24 },
    inputSize: 105,
    exerciseKey: "img/FriendsPlus/Page52/E3/Key/answerKey.png",
    maxLength: 1,

    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          " <audioimage name='08'></audioimage> Listen to Nam and Việt talking. Write <i>true</i> or <i>false</i>.",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div style="display: flex">
          <div>
            <div><b>1.</b> Nam wants to see the Red Square.  <b style="color: grey"><i> true </i></b></div>
            <div><b>2.</b> It is snowy in Moscow in January. <input id='0' /></div>
            <div><b>3.</b> Nam and Việt remember Gate 20.   <input id='1' /></div>
            <div><b>4.</b> Nam and Việt were the last people to get on the plane to Moscow.   <input id='2' /></div>
            <div><b>5.</b> The two flights to Moscow leave at the same time.   <input id='3' /></div>
            <div><b>6.</b> Việt knows which airline they are flying with.     <input id='4' /></div>
            <div><b>7.</b> Nam and Việt get on their plane at Gate 11.     <input id='4' /></div>
            <div><b>8.</b> Nam and Việt depart at 9 o’clock.    <input id='4' /></div>
          </div>
			  </div>
        `,
        answer: ["true", "false", "false", "false", "false", "false", "false"],
      },
    ],
  },

  4: {
    unit: "Unit 8",
    id: "WB7-U8-P52-E4",
    audio: "Audios/1-08 TA 7 Friends Plus Workbook.mp3",
    component: T6,
    questionImage: [],
    inputSize: 220,
    exerciseKey: "img/FriendsPlus/Page52/E4/Key/answerKey.png",
    stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "4",
        title:
          " <audioimage name='08'></audioimage> Listen again and fill in each blank with NO MORE THAN THREE WORDS.",
        color: "rgb(245, 128, 36)",
        star: 3
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Nam really wants to see the # Lomonosov when he reaches Moscow.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Việt hopes the weather in Moscow # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Việt’s sister said that the snow was in Moscow # in January last year.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Nam doesn’t want to be # to get on the plane.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Nam and Việt have to go to # to get on the plane.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Their flight # 9:15.</div></div>
                <div style=" text-align: center; margin: 30px"><img style="width: 50%" src="img/FriendsPlus/Page52/E4/1.jpg"/></div>
              </div>
          `,
        answer: [
          "University of",
          "isn't too cold",
          "one metre thick",
          "the last",
          "Gate",
          "leaves at",
        ],
      },
    ],
  },
}

export default json;