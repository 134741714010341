import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB7-U1-P9-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 143,
    exerciseKey: "img/FriendsPlus/Page9/E1/Key/answerKey.png",
    maxLength: 13,
    questions: [
      {
        title: `
          <div style=" position: relative;">
            <div><img src='img/FriendsPlus/Page9/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 215px; left: 140px; "><input id='0' /></div>
              <div style=" position: absolute; top: 335px; left: 140px; "><input id='1' /></div>
              <div style=" position: absolute; top: 369px; left: 140px; "><input id='2' /></div>
            </div>
          </div>
        `,
        answer: ["spends", "don't spend", "doesn't spend"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB7-U1-P9-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          " Write the sentences using the present simple affirmative (✔) or negative (✘) form of the verbs.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>Lily / spend / a lot of time in front of the TV ✘<br /><u style="text-decoration: dotted underline;"><i><b>Lily doesn’t spend a lot of time in front of the TV.</b></i>&emsp;</u></div>
              <div><b>1</b> Alex / like / video games ✔<br /><input id='0' /></div>
              <div><b>2</b> we / like / scary films ✘<br /><input id='1' /></div>
              <div><b>3</b> Osman / study / in his room ✔<br /><input id='2' /></div>
              <div><b>4</b> they / use / their phones in class ✘<br /><input id='3' /></div>
              <div><b>5</b> I / read / in bed at night ✘<br /><input id='4' /></div>
              <div><b>6</b> my mum / teach / history ✔<br /><input id='5' /></div>
              <div><b>7</b> our uncle / go / to the shops in town ✘<br /><input id='6' /></div>
              <div><b>8</b> you / write / good short stories ✔<br /><input id='7' /></div>
            </div>
        `,
        answer: [
          "Alex likes video games.",
          "We don't like scary films.",
          "Osman studies in his room.",
          "They don't use their phones in class.",
          "I don't read in bed at night.",
          "My mum teaches history.",
          "Our uncle doesn't go to the shops in town.",
          "You write good short stories.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB7-U1-P9-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 145,
    exerciseKey: "img/FriendsPlus/Page9/E3/Key/answerKey.png",
    textAlign: "center",
    maxLength: 9,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style=" position: relative;">
            <div><img src='img/FriendsPlus/Page9/E3/1.jpg' /></div>
            <div>
              <div style="position: absolute; top: 225px; left: 86px;"><input id='0' /></div>
              <div style="position: absolute; top: 252px; left: 168px;"><input id='1' /></div>
              <div style="position: absolute; top: 279px; left: 327px;"><input id='2' /></div>
              <div style="position: absolute; top: 361px; left: 43px;"><input id='3' /></div>
              <div style="position: absolute; top: 388px; left: 41px;"><input id='4' /></div>
              <div style="position: absolute; top: 469px; left: 164px;"><input id='5' /></div>
              <div style="position: absolute; top: 523px; left: 177px;"><input id='6' /></div>
              <div style="position: absolute; top: 577px; left: 43px;"><input id='7' /></div>
            </div>
          </div>
        `,
        answer: [
          "don't spend",
          "play",
          "goes",
          "don't see",
          "eat",
          "doesn't go",
          "watches",
          "listens",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P9-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [{
      src: [
        "allow",
        "finish",
        "learn",
        "let",
        "play",
        "spend ",
        "time ",
        "start",
        "study",
        "teach",
        "use",
        "watch",
      ],
      borderColor: "#69d2e4",
      width: "74%"
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          "Write sentences about your school with the verbs.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div>
            <div><hintbox id="0"></hintbox></div>
            <div>
              <div><b>Affirmative</b></div>
              <div><textarea id="0" rows="6">Our lessons start at 8.30. </textarea></div>
              <div><b>Negative</b></div>
              <div><textarea id="1" rows="6">My teacher doesn’t allow phones in class. </textarea></div>
            </div>
          </div>
          
        `,
        answer: [],
      },
    ],
  },
};

export default json;
