import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB7-U2-P19-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page19/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Choose the correct words",
        color: "#00a23b",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "however / but", //0
          "However / But", //1
          "however / but", //0
          "However / But", //1
          "however / but", //0
          "However / But", //1
        ],
        answers: ["0-4", "1-0", "2-4", "3-0", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
              <div>
                <div>All the students are learning languages. <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">However,</b> / <b>But</b>  most people don’t use them outside class.<div>
                <div><b>1.</b> A few students are studying German, <b><input id='0' type='Circle' /></b>  nobody is learning Italian.</div>
                <div><b>2.</b> Everybody is learning English. <b><input id='1' type='Circle' /></b> only a few students have an English dictionary.</div>
                <div><b>3.</b> I enjoy studying languages, <b><input id='2' type='Circle' /></b> I’m not interested in science.</div>
                <div><b>4.</b> We want to learn Spanish. <b><input id='3' type='Circle' /></b> there isn’t a Spanish teacher at our school.</div>
                <div><b>5.</b> Nam watches English films,<b><input id='4' type='Circle' /></b> he doesn’t use English websites.</div>
                <div><b>6.</b> Most people use English websites.  <b><input id='5' type='Circle' /></b> , only one or two people post comments. </div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "WB7-U2-P19-E2",
    component: T6,
    inputSize: 1000,
    exerciseKey: "img/FriendsPlus/Page19/E2/Key/answerKey.png",

    titleQuestion: [
      {
        num: "2",
        title: " Write sentences with <i>but</i> or <i>however</i>.",
        color: "#00a23b",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>I like history. I don’t like maths. (but)</div>
                  <div>&emsp; <u style="text-decoration: dotted underline;">&emsp;<b>I like history, but I don't like maths.</b>&emsp;</u></div>
                  <div>Most students in our class watch foreign films. Nobody watches foreign TV. (however)</div>
                  <div>&emsp;<u style="text-decoration: dotted underline;">&emsp;<b>Most students in our class watch foreign films. However, nobody watches foreign TV.</b>&emsp;</u></div>
                  <div><b>1</b> She isn’t a big fan of French. She’s interested in Spanish. (but)</div>
                  <div>&emsp; #</div>
                  <div><b>2</b> She isn’t a big fan of French. She’s interested in Spanish. (but)</div>
                  <div>&emsp; #</div>
                  <div><b>3</b> They started learning Japanese. The classes were very expensive. (however) </div>
                  <div>&emsp; #</div>
                  <div><b>4</b> We’re studying French. We can’t say very much. (but) </div>
                  <div>&emsp; #</div>
                  <div><b>5</b> Toby’s in the classroom. He wants to be in the sports hall with his friends. (but) </div>
                  <div>&emsp; #</div>
                </div>
            `,
        answer: [
          "She isn’t a big fan of French, but she’s interested in Spanish.",
          "The English exam was difficult. However, I think I passed.",
          "They started learning Japanese. However, the classes were very expensive.",
          "We’re studying French, but we can’t say very much.",
          "Toby’s in the classroom, but he wants to be in the sports hall with his friends.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB7-U2-P19-E3",
    component: T6,
    inputSize: 230,
    exerciseKey: "img/FriendsPlus/Page19/E3/Key/answerKey.png",

    stylesTextInput: { fontSize: 25, textAlign: "center" },
    hintBox: [
      {
        src: [
          "a few",
          "everybody",
          "half of",
          "less than half ",
          "more than half",
          "nobody",
        ],
        borderColor: "#00a23b",
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title:
          " Read the results of the survey and complete the report using the information in the table and the word(s) in the box.",
        color: "#00a23b",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div style="margin-bottom: 20px"><hintbox id="0"></hintbox></div>
                  <div>
                    <table style="border: solid 1px black; width: 100%">
                    <tr style="border: solid 1px black;  "><div style="padding-left: 10px; font-size: 30px"><b>The results of our survey</b></div></tr>
                    <tr style="border: solid 1px black; "><div style="padding: 5px 0 0 5px"><b><div>Survey about</div><div>What people do at lunchtime at school</div></b></div></tr>
                    <tr style="border: solid 1px black; ">
                      <td style="border: solid 1px black; padding-left: 5px ">Listen to music</td>
                      <td style="border: solid 1px black; padding: 0 30px 0 5px ">10 / 10</td>
                    </tr>
                    <tr style="border: solid 1px black; ">
                      <td style="border: solid 1px black; padding-left: 5px ">Play an instrument</td>
                      <td style="border: solid 1px black; padding: 0 30px 0 5px ">3 / 10</td>
                    </tr>
                    <tr style="border: solid 1px black; ">
                      <td style="border: solid 1px black; padding-left: 5px ">Meet friends in the playground</td>
                      <td style="border: solid 1px black; padding: 0 30px 0 5px ">7 / 10</td>
                    </tr>
                    <tr style="border: solid 1px black; ">
                      <td style="border: solid 1px black; padding-left: 5px ">Stay in the classroom</td>
                      <td style="border: solid 1px black; padding: 0 30px 0 5px ">0 / 10</td>
                    </tr>
                    <tr style="border: solid 1px black; ">
                      <td style="border: solid 1px black; padding-left: 5px ">Post messages on social media </td>
                      <td style="border: solid 1px black; padding: 0 30px 0 5px ">5 / 10</td>
                    </tr>
                    <tr style="border: solid 1px black; ">
                      <td style="border: solid 1px black; padding-left: 5px ">Use instant messaging</td>
                      <td style="border: solid 1px black; padding: 0 30px 0 5px ">4 / 10</td>
                    </tr>
                    <tr style="border: solid 1px black; ">
                      <td style="border: solid 1px black; padding-left: 5px ">Go to the library</td>
                      <td style="border: solid 1px black; padding: 0 30px 0 5px ">2 / 10</td>
                    </tr>
                    </table>
                  </div>
                  <div style="border: solid 1px rgb(188, 189, 192); border-radius: 10px; box-shadow: 3px 3px 3px rgb(191, 195, 192); padding: 20px; margin-block: 30px">
                    <b>Survey on hobbies: Report</b></br>
                    These are the results of our survey on what people 
                    do at lunchtime at school  . The results are 
                    from interviews with ten students.
                    <span style="display: inline-block"><b>1.</b> #</span> in our group listens <span style="display: inline-block"><b>2.</b> #</span> 
                     , but only <span style="display: inline-block"><b>3.</b> #</span>
                    people <span style="display: inline-block"><b>4.</b> #</span>
                    like the guitar.
                    <span style="display: inline-block"><b>5.</b> #</span> of 
                    the group meet friends in the playground. However, 
                    <span style="display: inline-block"><b>6.</b> #</span> stays <span style="display: inline-block"><b>7.</b> #</span>
                     . 
                    <span style="display: inline-block"><b>8.</b> #</span> the people post 
                    messages on social media, but <span style="display: inline-block"><b>9.</b> #</span>
                    of the students <span style="display: inline-block"><b>10.</b> #</span>. instant messaging now. Only 
                    two people go <span style="display: inline-block"><b>11.</b> #</span>.
                     , but more people probably like reading 
                    books when it isn’t lunchtime.
                  </div>
                </div>
            `,
        answer: [
          "Everybody",
          "to music",
          "a few",
          "play an instrument ",
          "More than half",
          "nobody",
          "in the classroom ",
          "Half of",
          "less than half ",
          "use",
          "to the library",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB7-U2-P19-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Imagine you do a survey on what people do at lunchtime at your school. Use some different activities.Then write a report about the results.",
        color: "#00a23b",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="11"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
