import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
	1: {
		unit: "Unit 1",
		id: "WB7-U1-P10-E1",
		audio: "",
		video: "",
		component: MatchDots,
		exerciseKey: "img/FriendsPlus/Page10/E1/Key/answerKey.png",
		// titleQuestion: [{ num: '2', title: "Listen again and draw the route to Leo's school.", color: "#2E479B" }],
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{ boxMatchStyle: { position: "absolute", top: 110, left: 260, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //0
					{ boxMatchStyle: { position: "absolute", top: 140, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //1
					{ boxMatchStyle: { position: "absolute", top: 140, left: 260, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //2
					{ boxMatchStyle: { position: "absolute", top: 170, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //3
					{ boxMatchStyle: { position: "absolute", top: 200, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //4
					{ boxMatchStyle: { position: "absolute", top: 200, left: 260, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //5
					{ boxMatchStyle: { position: "absolute", top: 230, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //6
					{ boxMatchStyle: { position: "absolute", top: 230, left: 260, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //7
					{ boxMatchStyle: { position: "absolute", top: 260, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //8
					{ boxMatchStyle: { position: "absolute", top: 260, left: 260, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //9
					{ boxMatchStyle: { position: "absolute", top: 290, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //10
					{ boxMatchStyle: { position: "absolute", top: 290, left: 260, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //11
				],
				answers: ["0-1", "3-5", "2-4", "11-6", "7-8", "10-9"],
				initialValue: [],
			},
			Layout: `
        <img src='img/FriendsPlus/Page10/E1/1.jpg' />
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
        <input id='11' type= 'boxMatch' />
      `,
		},
	},
	2: {
		unit: "Unit 1",
		id: "WB7-U1-P10-E2",
		audio: "",
		video: "",
		component: D1,
		padding: 0,
		maxLength: 1,
		textAlign: "center",
		exerciseKey: "img/FriendsPlus/Page10/E2/Key/answerKey.png",
		question: [],
		fontSize: 20,
		questionImage: [
			[{ url: "img/FriendsPlus/Page10/E2/1.jpg" }],
			[
				{ url: "img/FriendsPlus/Page10/E2/2.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/3.jpg", input: true, answer: "m" }, // 1
				{ url: "img/FriendsPlus/Page10/E2/4.jpg" },
			],
			[
				{ url: "img/FriendsPlus/Page10/E2/5.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/6.jpg", input: true, answer: "c" }, // 3
				{ url: "img/FriendsPlus/Page10/E2/7.jpg" },
			],
			[
				{ url: "img/FriendsPlus/Page10/E2/8.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/9.jpg", input: true, answer: "b" }, // 4
				{ url: "img/FriendsPlus/Page10/E2/10.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/11.jpg", input: true, answer: "e" }, // 1
				{ url: "img/FriendsPlus/Page10/E2/12.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/13.jpg", input: true, answer: "o" }, // 3
				{ url: "img/FriendsPlus/Page10/E2/14.jpg" },
			],
			[
				{ url: "img/FriendsPlus/Page10/E2/15.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/16.jpg", input: true, answer: "p" },
				{ url: "img/FriendsPlus/Page10/E2/17.jpg", input: true, answer: "a" },
				{ url: "img/FriendsPlus/Page10/E2/18.jpg", input: true, answer: "i" },
				{ url: "img/FriendsPlus/Page10/E2/19.jpg", input: true, answer: "n" },
				{ url: "img/FriendsPlus/Page10/E2/20.jpg", input: true, answer: "t" },
				{ url: "img/FriendsPlus/Page10/E2/21.jpg", input: true, answer: "s" },
				{ url: "img/FriendsPlus/Page10/E2/22.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/23.jpg", input: true, answer: "l" }, // 3
				{ url: "img/FriendsPlus/Page10/E2/24.jpg" },
			],
			[
				{ url: "img/FriendsPlus/Page10/E2/25.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/26.jpg", input: true, answer: "k" }, // 4
				{ url: "img/FriendsPlus/Page10/E2/27.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/28.jpg", input: true, answer: "t" }, // 6
				{ url: "img/FriendsPlus/Page10/E2/29.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/30.jpg", input: true, answer: "l" }, // 3
				{ url: "img/FriendsPlus/Page10/E2/31.jpg" },
			],
			[
				{ url: "img/FriendsPlus/Page10/E2/32.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/33.jpg", input: true, answer: "g" },
				{ url: "img/FriendsPlus/Page10/E2/34.jpg", input: true, answer: "o" },
				{ url: "img/FriendsPlus/Page10/E2/35.jpg", input: true, answer: "e" },
				{ url: "img/FriendsPlus/Page10/E2/36.jpg", input: true, answer: "s" },
				{ url: "img/FriendsPlus/Page10/E2/37.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/38.jpg", input: true, answer: "m" },
				{ url: "img/FriendsPlus/Page10/E2/39.jpg", input: true, answer: "a" },
				{ url: "img/FriendsPlus/Page10/E2/40.jpg", input: true, answer: "k" },
				{ url: "img/FriendsPlus/Page10/E2/41.jpg", input: true, answer: "e" },
				{ url: "img/FriendsPlus/Page10/E2/42.jpg", input: true, answer: "s" },
				{ url: "img/FriendsPlus/Page10/E2/43.jpg" },
			],
			[
				{ url: "img/FriendsPlus/Page10/E2/44.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/45.jpg", input: true, answer: "o" }, // 7
				{ url: "img/FriendsPlus/Page10/E2/46.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/47.jpg", input: true, answer: "y" }, // 6
				{ url: "img/FriendsPlus/Page10/E2/48.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/49.jpg", input: true, answer: "c" }, // 3
				{ url: "img/FriendsPlus/Page10/E2/50.jpg" },
			],
			[
				{ url: "img/FriendsPlus/Page10/E2/51.jpg" },
				{ url: "img/FriendsPlus/Page10/E2/52.jpg", input: true, answer: "t" }, // 3
				{ url: "img/FriendsPlus/Page10/E2/53.jpg" },
			],
			[{ url: "img/FriendsPlus/Page10/E2/54.jpg" }],
		],
	},
	3: {
		unit: "Unit 1",
		id: "WB7-U1-P10-E3",
		audio: "Audios/1-01 TA 7 Friends Plus Workbook.mp3",
		video: "",
		component: DesignUnderLine,
		totalInput: 5,
		exerciseKey: "img/FriendsPlus/Page10/E3/Key/answerKey.png",
		titleImage: "",
		questionImage: [
			[{ url: "img/FriendsPlus/Page10/E3/1.jpg" }],
			[
				{ url: "img/FriendsPlus/Page10/E3/2.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/3.jpg", input: 1, isCorrect: true },
				{ url: "img/FriendsPlus/Page10/E3/4.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/5.jpg", input: 1 },
				{ url: "img/FriendsPlus/Page10/E3/6.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/7.jpg", input: 1 },
				{ url: "img/FriendsPlus/Page10/E3/8.jpg" },
			],
			[{ url: "img/FriendsPlus/Page10/E3/9.jpg" }],
			[
				{ url: "img/FriendsPlus/Page10/E3/10.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/11.jpg", input: 2 },
				{ url: "img/FriendsPlus/Page10/E3/12.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/13.jpg", input: 2 },
				{ url: "img/FriendsPlus/Page10/E3/14.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/15.jpg", input: 2, isCorrect: true },
				{ url: "img/FriendsPlus/Page10/E3/16.jpg" },
			],
			[{ url: "img/FriendsPlus/Page10/E3/17.jpg" }],
			[
				{ url: "img/FriendsPlus/Page10/E3/18.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/19.jpg", input: 3, isCorrect: true },
				{ url: "img/FriendsPlus/Page10/E3/20.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/21.jpg", input: 3 },
				{ url: "img/FriendsPlus/Page10/E3/22.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/23.jpg", input: 3 },
				{ url: "img/FriendsPlus/Page10/E3/24.jpg" },
			],
			[{ url: "img/FriendsPlus/Page10/E3/25.jpg" }],
			[
				{ url: "img/FriendsPlus/Page10/E3/26.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/27.jpg", input: 4 },
				{ url: "img/FriendsPlus/Page10/E3/28.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/29.jpg", input: 4, isCorrect: true },
				{ url: "img/FriendsPlus/Page10/E3/30.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/31.jpg", input: 4 },
				{ url: "img/FriendsPlus/Page10/E3/32.jpg" },
			],
			[{ url: "img/FriendsPlus/Page10/E3/33.jpg" }],
			[
				{ url: "img/FriendsPlus/Page10/E3/34.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/35.jpg", input: 5 },
				{ url: "img/FriendsPlus/Page10/E3/36.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/37.jpg", input: 5 },
				{ url: "img/FriendsPlus/Page10/E3/38.jpg" },
				{ url: "img/FriendsPlus/Page10/E3/39.jpg", input: 5, isCorrect: true },
				{ url: "img/FriendsPlus/Page10/E3/40.jpg" },
			],
			[{ url: "img/FriendsPlus/Page10/E3/41.jpg" }],
		],
	},
	4: {
		unit: "Unit 1",
		id: "WB7-U1-P10-E4",
		audio: "Audios/1-01 TA 7 Friends Plus Workbook.mp3",
		video: "",
		component: T6,
		questionImage: [],

		inputSize: 120,
		exerciseKey: "img/FriendsPlus/Page10/E4/Key/answerKey.png",
		maxLength: 7,

		textAlign: 'center',
		questions: [
			{
				title: `
          <div>
            <div><img src='img/FriendsPlus/Page10/E4/1.jpg' /></div>
            <div>
              <div>Alfie draws pictures of <s><b>animals</b></s>. <u style="text-decoration: dotted underline;">&emsp;<i><b>people</b></i>&emsp;</u></div>
              <div><b>1</b> Alfie sometimes makes <b>videos</b>. <input id='0' /></div>
              <div><b>2</b> There are <b>twelve</b> posters in Alfie’s room. <input id='1' /></div>
              <div><b>3</b> Megan has got a big <b>camera.</b> <input id='2' /></div>
              <div><b>4</b> Megan never plays football on <b>Tuesdays.</b> <input id='3' /></div>
              <div><b>5</b> Megan meets her <b>sisters</b> in town after school. <input id='4' /></div>
              <div><b>6</b> Luke likes listening to <b>old</b> music. <input id='5' /></div>
              <div><b>7</b> Luke <b>never</b> goes online. <input id='6' /></div>
              <div><b>8</b> Luke sometimes plays a musical instrument at <b>home</b>. <input id='7' /></div>
            </div>
          </div>
        `,
				answer: [
					"posters",
					"twenty",
					"bag",
					"Fridays",
					"friends",
					"new",
					"often",
					"school",
				],
			},
		],
	},
	5: {
		unit: "Unit 1",
		id: "WB7-U1-P10-E5",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "5",
				title:
					" Write sentences about the hobbies that you, your friends and your family have. Use the phrases on this page to help you.",
				color: "#efa162",
				star: 3
			},
		],
		questions: [
			{
				title: `
          <div>
            <div><textarea id="0" rows="10">My parents often go to the cinema and they watch films on TV. </textarea></div>
          </div>
        `,
				answer: [],
			},
		],
	},
};

export default json;
