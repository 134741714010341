import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 7",
    id: "WB7-U7-P46-E1",
    component: T6,
    questionImage: [],
    exerciseKey: "img/FriendsPlus/Page46/E1/Key/answerKey.png",
    stylesTextInput: { textAlign: 'left', marginInline: '1px', border: "none" },
    isHiddenCheck: true,
    maxLength: 10,
    titleQuestion: [
      {
        num: "1",
        title:
          " Look at the pictures. Complete the words.",
        color: "rgb(245, 128, 36)",
        star: 1
      },
    ],
    questions: [
      {
        title: `
              <div style="position: relative; display:flex; min-width: 1200px">
                <div>
                  <div>
                    He’s really <b>en</b>
                    <u style="color: grey; margin-inline: 3px"><b>e</b></u>
                    <u style="color: grey; margin-inline: 3px"><b>r</b></u>
                    <u style="color: grey; margin-inline: 3px"><b>g</b></u>
                    <u style="color: grey; margin-inline: 3px"><b>e</b></u>
                    <u style="color: grey; margin-inline: 3px"><b>t</b></u>
                    <u style="color: grey; margin-inline: 3px"><b>i</b></u>
                    <u style="color: grey; margin-inline: 3px"><b>c</b></u>
                  </div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> He’s very <b>su</b> _ _ _ _ _ _ _ _</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> We like <b>co</b> _ _ _ _ _ _ _ _</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> She wants to be a <b>st</b> _ _ _ _ _ _</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> My brother plays the <b>ta</b> _ _ _ _</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Her favourite music is by Run-DMC, a <b>fa</b> _ _ _ _</div></div>
                </div>
                <div style="text-align: center"><img style="width: 60%"src='img/FriendsPlus/Page46/E1/1.jpg' /></div>
              
              <div style="position: absolute; top: 37px; left: 200px;"><input id='0' width="160px"/></div>
              <div style="position: absolute; top: 75px; left: 178px;"><input id='1' width="160px"/></div>
              <div style="position: absolute; top: 113px; left: 282px;"><input id='2' width="130px"/></div>
              <div style="position: absolute; top: 152px; left: 310px;"><input id='3' width="100px"/></div>
              <div style="position: absolute; top: 189px; left: 492px;"><input id='4' width="100px"/></div>
              </div>
          `,
        answer: [
          "ccessful",
          "nfidence",
          "rength",
          "lent",
          "mous",
        ]
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "WB7-U7-P46-E2",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page46/E2/Key/answerKey.png",

    hintBox: [{
      src: [
        "ambition / ambitious",
        "charm / charming ",
        "<s>confidence / confident</s>",
        "energy / energetic ",
        "fame / famous",
        "success / successful ",
        "talent / talented",
      ],
      width: "100%",
      borderColor: "rgb(245, 128, 36)",
    }],
    stylesTextInput: { marginInline: '10px', fontSize: 24 },
    titleQuestion: [
      {
        num: "2",
        title:
          " Complete the sentences with one of the words in each pair.",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div><hintbox id='0'></hintbox></div>
                <div> Finley isn’t worried about singing in front of 1,000 people. He’s really <u style="text-decoration: dotted underline;color: grey">&emsp;<b>confident </b>&emsp;</u> </div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Amanda wants to be the most famous singer in the world. She’s got a lot of # !</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> This hit has got the most downloads ever. It’s a big # for the band.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> When the band play a concert they never stop dancing. They’re really # !</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Barbora can play six different instruments. She’s the most # person I know.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> That singer doesn’t want to be on TV or in videos. He isn’t interested in # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Everybody likes my uncle Brad. He’s a kind and # person.</div></div>
              </div>
          `,
        answer: [
          "ambition",
          "success",
          "energetic",
          "talented",
          "fame",
          "charming",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 7",
    id: "WB7-U7-P46-E3",
    audio: "Audios/1-07 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 35,
    exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    maxLength: 1,

    isHiddenCheck: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "<audioimage name='07'></audioimage> Listen to Freddie and his sister Lucy. They’re talking about a TV programme, <i>You’ve Got Talent!</i> Match the contestants(1–6) with Lucy and Freddie’s opinions(a–f).",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div style="padding-top: 15px; border-spacing:0 15px;">
                <tr style="margin-block: 5px">
                  <td style="padding: 0px 30px;"><b> 1 </b></td>
                  <td style=" border: 1px solid black; text-align: center; color: grey; font-weight: bold">f</td>
                  <td style="padding-left: 40px">Samba Rocks! </td>
                  <td>&emsp;&emsp;&emsp;&emsp;</td>
                  <td style="padding: 0px 30px;"><b> 4 </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Ashraf Osman </td>
                </tr>
                <tr style="margin-block: 5px">
                  <td style="padding: 0px 30px;"><b> 2 </b></td>
                  <td style=" border: 1px solid black; width: 40px; height: 40px">#</td>
                  <td style="padding-left: 40px"> Dance Heroes </td>
                  <td>&emsp;&emsp;&emsp;&emsp;</td>

                  <td style="padding: 0px 30px;"><b> 5 </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px">Kim Kelly </td>
                </tr>
                <tr style="margin-block: 5px">
                  <td style="padding: 0px 30px;"><b> 3 </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Salsa Nation</td>
                  <td>&emsp;&emsp;&emsp;&emsp;</td>
                
                  <td style="padding: 0px 30px;"><b> 6 </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Grace Morgan </td>
                </tr>
              </div>
            </div>
            <div style="padding-top: 15px">
                <tr>
                  <td style="padding: 0px 30px;"><b> a </b></td>
                  <td style="padding-left: 10px">Talented and kind, but without confidence </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> b </b></td>
                  <td style="padding-left: 10px"> Confident with a lot of ambition</td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> c </b></td>
                  <td style="padding-left: 10px"> Successful and with real talent </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> d </b></td>
                  <td style="padding-left: 10px"> Energetic and extremely strong </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> e </b></td>
                  <td style="padding-left: 10px"> Ambitious, but weak and not very talented</td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> f </b></td>
                  <td style="padding-left: 10px"> Charming, but with one weakness </td>
                </tr>
              </div>

        `,
        answer: [
          "b", //4
          "d", //2
          "a", //5
          "e", //3
          "c", //6
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "WB7-U7-P46-E4",
    audio: "Audios/1-07 TA 7 Friends Plus Workbook.mp3",
    component: T6,
    questionImage: [],
    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page46/E4/Key/answerKey.png",

    stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='07'></audioimage> Listen again. Complete the sentences with one word.",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>You’ve Got Talent! was very <u style="text-decoration: dotted underline;color: grey">&emsp;<b>exciting </b>&emsp;</u>  last week.</div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> The samba dance band has got # dancers.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Lucy thinks Samba Rocks! # win.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Dance Heroes danced to a famous # song.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Freddie thinks Salsa Nation will go # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Ashraf sings and plays the # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Kim is only # years old.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> Grace is # than the other contestants.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> Freddie thinks Grace be # in the final.</div></div>
              </div>
          `,
        answer: [
          "ten / 10",
          "won't / will not",
          "rock",
          "home",
          "guitar",
          "sixteen / 16",
          "older",
          "will",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 7",
    id: "WB7-U7-P46-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Write sentences about three famous singers, actors or sports people using some of the words on this page.",
        color: "rgb(245, 128, 36)",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <textarea id="0" rows="11">Ellie Goulding is one of my favourite singers. She’s gota lot of talent and she’s successful in differentcountries around the world</textarea>
        `,
        answer: [],
      },
    ],
  },
}

export default json;