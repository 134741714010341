import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {

  1: {
    unit: "Unit 1",
    id: "WB7-U1-P13-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page13/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Choose the correct words",
        color: "#00a23b",
        star: 1
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "too / also.", //0
          "and / too", //1
          "and / also", //2
          "also / too?", //3
          "too / also", //4
          "and / also", //5
        ],
        answers: ["0-0", "1-0", "2-4", "3-4", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
              <div>
                <div>They go swimming. They’re <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">also</b> / <b>too</b>  interested in athletics.<div>
                <div><b>1</b> Fatma enjoys drawing. She loves going shopping, <b><input id='0' type='Circle' /></b></div>
                <div><b>2</b> I’m a big fan of Katy Perry <b><input id='1' type='Circle' /></b> I like Taylor Swift.</div>
                <div><b>3</b> We love going dancing. We <b><input id='2' type='Circle' /></b> enjoy drawing pictures.</div>
                <div><b>4</b> Jamal is good at football. Is he good at basketball, <b><input id='3' type='Circle' /></b></div>
                <div><b>5</b> This is an interesting film. It’s <b><input id='4' type='Circle' /></b> very exciting.</div>
                <div><b>6</b> They’re creative <b><input id='5' type='Circle' /></b> they really like making pop videos.</div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "WB7-U1-P13-E2",
    component: T6,
    inputSize: 500,
    exerciseKey: "img/FriendsPlus/Page13/E2/Key/answerKey.png",

    titleQuestion: [
      {
        num: "2",
        title:
          "Order the words to complete the sentences.",
        color: "#00a23b",
        star: 2
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>TV / <s>watch</s> / go / . / also / to / <s>We</s> / cinema / the / . / We</div>
                  <div>&emsp;We watch <u style="text-decoration: dotted underline;">&emsp;<b>TV. We also go to the cinema.</b>&emsp;</u></div>
                  <div><b>1</b> the / <s>I</s> / play / and / piano / . / pictures / I / <s>paint</s></div>
                  <div>&emsp;I paint #</div>
                  <div><b>2</b> <s>like</s> / <s>They</s> / cakes / baking / too / . / stories / write / They / .</div>
                  <div>&emsp;They like #</div>
                  <div><b>3</b> online / <s>Kate</s> / videos / <s>goes</s> / . / makes / She / . / also</div>
                  <div>&emsp;Kate goes #</div>
                  <div><b>4</b> <s>interested</s> / and / <s>He’s</s> / sport / . / likes / in / football / he /</div>
                  <div>&emsp;He’s interested #</div>
                  <div><b>5</b> <s>goes</s> / <s>My sister</s> / basketball / horse-riding / . / too / plays / She / .</div>
                  <div>&emsp;My sister goes #</div>
                  <div><b>6</b> <s>collect</s> / my friends / things / <s>I</s> / . / meet / also / . / town / in / I</div>
                  <div>&emsp;I collect #</div>
                </div>
            `,
        answer: [
          "pictures and I play the piano.",
          "baking cakes. They write stories too.",
          "online. She also makes videos.",
          "in sport and he likes football.",
          "horse-riding. She plays basketball too.",
          "things. I also meet my friends in town.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB7-U1-P13-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 340,
    exerciseKey: "img/FriendsPlus/Page13/E3/Key/answerKey.png",

    isHiddenCheck: true,
    questions: [
      {
        title: `
              <div style=" position: relative;">
                <div><img src='img/FriendsPlus/Page13/E3/1.jpg' /></div>
                <div>
                  <div style=" position: absolute; top: 930px; left: 45px; "><input id='0' width='255px' /></div>
                  <div style=" position: absolute; top: 963px; left: 79px; "><input id='1' width='190px' /></div>
                  <div style=" position: absolute; top: 996px; left: 151px; "><input id='2' width='295px' /></div>
                  <div style=" position: absolute; top: 1067px; left: 219px; "><input id='3' /></div>
                  <div style=" position: absolute; top: 1100px; left: 158px; "><input id='4' width='290px' /></div>
                  <div style=" position: absolute; top: 1132px; left: 64px; "><input id='5' width='350px' /></div>
                  <div style=" position: absolute; top: 1164px; left: 46px; "><input id='6' /></div>
                  <div style=" position: absolute; top: 1237px; left: 64px; "><input id='7' width='290px' /></div>
                  <div style=" position: absolute; top: 1237px; left: 407px; "><input id='8' /></div>
                  <div style=" position: absolute; top: 1269px; left: 145px; "><input id='9' width='190px' /></div>
                  <div style=" position: absolute; top: 1301px; left: 50px; "><input id='10' width='190px' /></div>
                  <div style=" position: absolute; top: 1374px; left: 69px; "><input id='11' width='295px' /></div>
                  <div style=" position: absolute; top: 1374px; left: 396px; "><input id='12' width='300px' /></div>
                  <div style=" position: absolute; top: 1407px; left: 113px; "><input id='13' /></div>
                </div>
              </div>
            `,
        answer: [
          "'m a student from Turkey",
          "English",
          "want to practise my English",
          "live in a village near the city of Izmir",
          "is in the centre of Izmir",
          "spend a lot of time on the school bus",
          "I spend most of my time in my room",
          "'ve got two younger brothers",
          "I've got an older sister, too",
          "is a teacher",
          "works in a shop",
          "'m mad about sport",
          "love playing football",
          "also enjoy playing a musical instrument",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P13-E4",
    component: T6,
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Your school finds a penfriend for you. Write a profile. Use the table and text in exercise 3 to help you.",
        color: "#00a23b",
        star: 3
      },
    ],
    questions: [
      {
        title: `
                <div><textarea id="0" rows="10"></textarea></div>
            `,
        answer: [],
      },
    ],
  },

};

export default json;