import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB7-U3-P21-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 105,
    exerciseKey: "img/FriendsPlus/Page21/E1/Key/answerKey.png",
    maxLength: 7,

    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Complete the table with was, were, wasn’t or weren’t",
        color: "#69d2e4",
        star: 1,
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div><img src='img/FriendsPlus/Page21/E1/1.jpg' /></div>
            <div>
              <div style="position: absolute; top: 85px; left: 199px;"><input id='0' /></div>
              <div style="position: absolute; top: 179px; left: 199px;"><input id='0' /></div>
              <div style="position: absolute; top: 223px; left: 199px;"><input id='0' /></div>
              <div style="position: absolute; top: 318px; left: 199px;"><input id='0' /></div>
              <div style="position: absolute; top: 358px; left: 199px;"><input id='0' /></div>
              <div style="position: absolute; top: 424px; left: 199px;"><input id='0' /></div>
              <div style="position: absolute; top: 466px; left: 199px;"><input id='0' /></div>
              <div style="position: absolute; top: 592px; left: 45px;"><input id='0' /></div>
              <div style="position: absolute; top: 643px; left: 45px;"><input id='0' /></div>
              <div style="position: absolute; top: 592px; left: 355px;"><input id='0' /></div>
              <div style="position: absolute; top: 643px; left: 355px;"><input id='0' /></div>
            </div>
          </div>
        `,
        answer: [
          "were",
          "wasn't",
          "weren't",
          "Was",
          "Were",
          "was",
          "were",
          "was",
          "were",
          "wasn't",
          "weren't",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB7-U3-P21-E2",
    component: Circle_Write,
    inputSize: 180,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page21/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          " Complete the questions with what, where or who, and was or were.Then choose the correct answer. ",
        color: "#2ec3d8",
        star: 2,
      },
    ],
    questionImage: [],
    question: {
      Write: {
        inputStyle: { width: 132, fontSize: 20, color: "black" },
        underlineStyle: { borderBottom: "none" },
        answers: [" What was ", " Who was ", " Where was "],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0 15px",
          fontWeight: 200,
          color: "black",
          fontSize: 20,
        },
        selectWordStyle: {
          border: "2px solid rgb(46, 195, 216)",
          color: "rgb(46, 195, 216)",
        },
        limitSelect: 1,
        listWords: [
          "A._Spain  B._France  C._Italy ",
          "A._Romeo_and_Juliet  B._Hamlet  C._King_Lear ",
          "A._George_Washington  B.__Abraham_Lincoln  C.__John_F._Kennedy ",
          "A._Rome  B._Cairo  C._Olympia ",
        ],
        answers: ["0-8", "1-0", "2-0", "3-8"],
        initialValue: [],
      },

      Layout: `
            <div><img src='img/FriendsPlus/Page21/E2/1.jpg'/></div> 
            <div position: relative">
              <div style="position: absolute; top: 290px; left: 469px;"><input id='0' type='Circle' /></div>
              <div style="position: absolute; top: 325px; left: 469px;"><input id='0' /></div>
              <div style="position: absolute; top: 415px; left: 469px;"><input id='1' type='Circle' /></div>
              <div style="position: absolute; top: 460px; left: 469px;"><input id='1' /></div>
              <div style="position: absolute; top: 550px; left: 360px;"><input id='2' type='Circle' /></div>
              <div style="position: absolute; top: 595px; left: 469px;"><input id='2' /></div>
              <div style="position: absolute; top: 685px; left: 469px;"><input id='3' type='Circle' /></div>
            </div>
          `,
    },
  },
  3: {
    unit: "Unit 3",
    id: "WB7-U3-P21-E3",
    component: T6,
    inputSize: "110px",
    exerciseKey: "img/FriendsPlus/Page21/E3/Key/answerKey.png",

    maxLength: 7,
    titleQuestion: [
      {
        num: "3",
        title: "Complete the sentences with was, wasn’t, were or weren’t.",
        color: "#69d2e4",
        star: 2,
      },
    ],
    stylesTextInput: { textAlign: "center" },
    questions: [
      {
        title: `
        <div>
          <div>
            <div style="color: grey;">The tunnels under the city He<b> were</b> dark and dangerous places in the 18th century.</div>
            <div><b>1</b>.  Cara # at the concert last Friday because she # ill.</div>
            <div><b>2</b>. We # interested in that long, boring story. It # very exciting.</div>
            <div><b>3</b>. Who # your guide at the museum?</div>
            <div><b>4</b>. The visitors # happy with the coffee in the café. It # cold and expensive!</div>
            <div><b>5</b>. The weather is good today, but yesterday it # really bad.</div>
            <div><b>6</b>.  ‘ #  you brave on the tour of the tunnels?’ ‘No, we  # !’</div>
            </div>
          </div>
        </div>`,
        answer: [
          " wasn't",
          "was ",
          "weren't",
          "wasn't ",
          "was",
          " weren't ",
          "was",
          "was",
          "Were",
          "weren't",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB7-U3-P21-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 150,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page21/E4/Key/answerKey.png",
    maxLength: 13,

    textAlign: "center",
    hintBox: [
      {
        src: [
          "<s>there was</s>",
          "there was",
          "There wasn’t",
          "there were",
          "There were",
          "there weren’t",
          "there weren’t",
        ],
        width: "80%",
        borderColor: "#77cedf",
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: " Complete the text with the words.",
        color: "#69d2e4",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div style="position: relative; margin: 22px">
            <div><img style="width:76%" src='img/FriendsPlus/Page21/E4/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 136px; left: 370px; "><input id='0' /></div>
              <div style=" position: absolute; top: 169px; left: 330px; "><input id='1' /></div>
              <div style=" position: absolute; top: 269px; left: 133px; "><input id='2' /></div>
              <div style=" position: absolute; top: 303px; left: 228px; "><input id='3' /></div>
              <div style=" position: absolute; top: 337px; left: 509px; "><input id='4' /></div>
              <div style=" position: absolute; top: 371px; left: 430px; "><input id='5' /></div>
            </div>
          </div>
        `,
        answer: [
          " There were ",
          "there was",
          "there weren't",
          "there were",
          "there weren't",
          "There wasn't",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB7-U3-P21-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [
      {
        src: [
          "actor",
          "café",
          "exhibition",
          "guide",
          "people",
          "pictures",
          "shop",
          "tour",
          "tourists",
          "visitors",
        ],
        width: "100%",
        borderColor: "#77cedf",
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title:
          "Imagine you went on a school trip. Write sentences about the trip with there was and there were.Use the words to help you.",
        color: "#69d2e4",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div>I went to Dover Castle. There were a lot of tourists and school groups.</div>
          <div><textarea id="0" rows="11">I went to </textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
