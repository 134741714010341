import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P64-E1",
    component: T6,
    questionImage: [],
    inputSize: 110,
    exerciseKey: "img/FriendsPlus/Page64/E1/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the affirmative (✔) and negative (✘) sentences with was, were, wasn’t or weren’t.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> I # having breakfast at 7:30. ✘</br>I # walking to school with Mia. ✔</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> We # climbing the mountain earlier. ✔</br>We # sleeping in our tent. ✘</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Sara # standing in the photo. ✘</br>She # sitting on the sofa. ✔</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> You # watching TV at 8:30. ✔</br>You # listening to music. ✘</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Mike # playing basketball today. ✔</br>He # swimming in the sea. ✘</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> It # snowing at 3:30 this afternoon. ✘</br>It # raining. ✔</div></div>
              </div>
          `,
        answer: [
          "wasn't", "was",
          "were", "weren't",
          "wasn't", "was",
          "were", "weren't",
          "was", "wasn't ",
          "wasn't", "was",
        ],
      },
    ],
  },
  2: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P64-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    inputSize: 625,
    exerciseKey: "img/FriendsPlus/Page64/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Write sentences using the affirmative or negative form of the past continuous.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div style="font-size: 22px">
              <div><b>At 7:00 yesterday evening …</b></br>my friends / play / volleyball <br /><u style="text-decoration: dotted underline; color: grey"><i><b>My friends were playing volleyball.</b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> Olga / have / her dinner  <br /> &emsp; #</div>
              <div><b>2 &emsp;</b> I / not study / for that important exam  <br /> &emsp; #</div>
              <div><b>3 &emsp;</b> Ed and Tom / wait / outside the cinema <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> Mustafa / write / an email <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> my parents / not watch / that new TV series <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> you / not answer / your mobile phone  <br /> &emsp; #</div>
              <div><b>7 &emsp;</b> I / chat / on social media <br /> &emsp; #</div>
              <div><b>8 &emsp;</b> they / not bake / a cake  <br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "Olga was having her dinner.",
          "I wasn't studying for that important exam",
          "Ed and Tom were waiting outside the cinema.",
          "Mustafa was writing an email.",
          "My parents weren't watching that new TV series.",
          "You weren't answering your mobile phone.",
          "I was chatting on social media.",
          "They weren't baking a cake.",
        ],
      },
    ],
  },

  3: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P64-E3",
    component: T6,
    questionImage: [],
    inputSize: 120,
    exerciseKey: "img/FriendsPlus/Page64/E3/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title:
          " Write questions using the past continuous form of the words.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">  #they # (stand) at the back in the photo?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">  #your sister # (dance) in that show?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  #you # (run) in the race?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  #Daisy # (wear) a new dress?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Why # Paul # (eat) his breakfast late?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Where # we # (sit) in that restaurant?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> What # you # (say) in the car?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> Who # he # (talk) to?</div></div>
              </div>
          `,
        answer: [
          "Were", "standing",
          "Was", "dancing ",
          "Were", "running ",
          "Was", "wearing ",
          "was", "eating ",
          "were", "sitting",
          "were", "saying ",
          "was", "talking",
        ],
      },
    ],
  },

  4: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P64-E4",
    component: T6,
    questionImage: [],
    inputSize: 160,
    exerciseKey: "img/FriendsPlus/Page64/E4/Key/answerKey.png",
    stylesTextInput: { marginInline: '1px', fontSize: 22, textAlign: 'center' },

    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the email using the past simple or past continuous form of the verbs.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="position: relative">
                  <div><img style="width:55%" src='img/FriendsPlus/Page64/E4/1.jpg' /></div>
                  <div style="position: absolute; left: 30px; top: 30px; font-size: 22px; line-height: 130%; word-spacing: 7px">
											Hi Molly,</br>
                      Guess what! I was cycling (cycle) home from your </br>
                      house yesterday when suddenly a little girl </br>
                      <sup>1</sup> # (walk) in front of me. She </br>
                      <sup>2</sup> # (not look) – her parents </br>
                      <sup>3</sup> # (stand) on the pavement and </br>
                      they <sup>4</sup> # (talk) to some friends. </br>
                      I <sup>5</sup> # (go) very fast on my bike, </br>
                      but I <sup>6</sup> # (stop) before I hit the </br>
                      girl and luckily I <sup>7</sup> # (not fall) </br>
                      off the bike. The girl’s mum was very nice – she </br>
                      <sup>8</sup> # (say) sorry to me and she </br>
                      <sup>9</sup> # (thank) me for stopping quickly.</br>
                      See you tomorrow,</br>
                      Florence</br>
									</div>
                </div>
          `,
        answer: [
          "walked",
          "wasn't looking",
          "were standing ",
          "were talking",
          "was going",
          "stopped",
          "didn't fall",
          "said",
          "thanked",
        ],
      },
    ],
  },

  5: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P64-E5",
    component: T6,
    questionImage: [],
    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page64/E5/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    hintBox: [{
      src: [
        "angry",
        "bad",
        "fast",
        "good",
        "<s>happy</s>",
        "hard",
      ],
      width: "558px",
      borderColor: "#4D3B98",
    }],
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the sentences with the adverbs of the adjectives in the box.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="width: 1000px; display: flex; justify-content: center; margin-block: 10px"><hintBox id='0'></hintbox></div>
                <div>
                  <div>‘It’s my birthday!’ the little girl said <u style="text-decoration: dotted underline;color: grey">&emsp;<b>happily</b>&emsp;</u>.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> I ran very # because I was late for school.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> ‘You broke my expensive new camera!’ she shouted #.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  I’m not good at football. I usually play #.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> My dad works very # . He has a long day at his office.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  My sister is an amazing singer. She always sings # .</div></div>
              </div>
          `,
        answer: [
          "fast",
          "angrily",
          "badly",
          "hard",
          "well",
        ],
      },
    ],
  },
}


export default json;