import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: "Language Focus Practice",
		id: "WB7-LFS-P62-E1",
		component: T6,
		questionImage: [],
		inputSize: 130,
		exerciseKey: "img/FriendsPlus/Page62/E1/Key/answerKey.png",

		stylesTextInput: { fontSize: 24, textAlign: 'center' },
		titleQuestion: [
			{
				num: "1",
				title:
					" Write the -ing form of the verbs.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
							<div>do &emsp; <u style="text-decoration: dotted underline; color: grey"><i><b>doing</b></i>&emsp;</u></div>
              <div style="display: flex">
                <div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">make</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">stop</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">collect</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">live</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">swim</div> #</div></div></div>
                </div>
                <div style="margin-left: 100px">
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">spend</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">stay</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">leave</div>#</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">paint</div>#</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">work</div>#</div></div></div>
                </div>
              </div>
          `,
				answer: [
					"making",
					"stopping",
					"collecting",
					"living",
					"swimming",
					"spending",
					"staying",
					"leaving",
					"painting",
					"working",
				],
			},
		],
	},

	2: {
		unit: "Language Focus Practice",
		id: "WB7-LFS-P62-E2",
		component: T6,
		questionImage: [],
		inputSize: 150,
		exerciseKey: "img/FriendsPlus/Page62/E2/Key/answerKey.png",

		stylesTextInput: { fontSize: 24, textAlign: 'center' },
		hintBox: [{
			src: [
				"bake",
				"<s>draw</s>",
				"eat",
				"meet",
				"play",
				"run",
				"watch",
			],
			width: "558px",
			borderColor: "#4D3B98",
		}],
		titleQuestion: [
			{
				num: "2",
				title:
					"Complete the dialogues using the present continuous form of the verbs.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
                <div style="width: 1000px; display: flex; justify-content: center; margin-block: 10px"><hintBox id='0'></hintbox></div>
                <div>
                  <div>‘Melisa is quiet.’ ‘Yes, she<u style="text-decoration: dotted underline;color: grey">&emsp;<b> ’s drawing</b>&emsp;</u>  a picture.’</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Where’s Freya?’ ‘She # a cake in the kitchen.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Are Leo and Jim here?’ ‘No, they aren’t. They # football with their friends.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘Are you in the kitchen?’ ‘No, I’m not. I # a film in the lounge.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> ‘John isn’t in # his room.’ ‘I know. He his friends in town.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘Where’s Dad?’ ‘He # his breakfast.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘What are Tomas and Eva doing?’ ‘They # for the bus because they’re late!’</div></div>
              </div>
          `,
				answer: [
					"'s baking",
					"'re playing",
					"'m watching ",
					"'s meeting",
					"'s eating",
					"'re running",
				],
			},
		],
	},

	3: {
		unit: "Language Focus Practice",
		id: "WB7-LFS-P62-E3",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		stylesTextInput: { fontSize: 22 },
		inputSize: 625,
		exerciseKey: "img/FriendsPlus/Page62/E3/Key/answerKey.png",
		titleQuestion: [
			{
				num: "3",
				title:
					"Write affirmative and negative sentences using the present continuous.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
            <div>
              <div>Jane / not study / listen to music <br /><u style="text-decoration: dotted underline; color: grey"><i><b>Jane isn’t studying. She’s listening to music.</b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> they / not make lunch / chat on social media  <br /> &emsp; #</div>
              <div><b>2 &emsp;</b> Tamer / not sit in his chair / run outside  <br /> &emsp; #</div>
              <div><b>3 &emsp;</b> I / not make a phone call / send an email <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> we / not listen to Tom / speak to Andy <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> Holly / not read her book / send a text message <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> you / not write in your notebook / talk  <br /> &emsp; #</div>
            </div>
        `,
				answer: [
					"They aren't making lunch. They're chatting on social media.",
					"Tamer isn't sitting in his chair. He's running outside.",
					"I'm not making a phone call. I'm sending an email.",
					"We aren't listening to Tom. We're speaking to Andy.",
					"Holly isn't reading her book. She's sending a text message.",
					"You aren't writing in your notebook. You're talking.",
				],
			},
		],
	},

	4: {
		unit: "Language Focus Practice",
		id: "WB7-LFS-P62-E4",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		stylesTextInput: { fontSize: 22 },
		inputSize: 625,
		exerciseKey: "img/FriendsPlus/Page62/E4/Key/answerKey.png",
		titleQuestion: [
			{
				num: "4",
				title:
					"Order the words to make present continuous questions.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
            <div>
              <div><b>1 &emsp;</b> you / What / watching / are / TV / on ?  <br /> &emsp; #</div>
              <div><b>2 &emsp;</b> Henry / Is / playing / with Sam / tennis ? <br /> &emsp; #</div>
              <div><b>3 &emsp;</b> your / Why / friends / are / fast / running ? <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> staying / on / Where / Angela / holiday / is ? <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> we / exercise / this / looking at / Are / now ? <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> to / speaking / Who / your brother / is ?  <br /> &emsp; #</div>
            </div>
        `,
				answer: [
					"What are you watching on TV?",
					"Is Henry playing tennis with Sam?",
					"Why are your friends running fast?",
					"Where is Angela staying on holiday?",
					"Are we looking at this exercise now?",
					"Who is your brother speaking to?",
				],
			},
		],
	},

	5: {
		unit: "Language Focus Practice",
		id: "WB7-LFS-P62-E5",
		component: T6,
		questionImage: [],
		inputSize: 140,
		exerciseKey: "img/FriendsPlus/Page62/E5/Key/answerKey.png",
		stylesTextInput: { marginInline: '10px', fontSize: 18, textAlign: 'center' },

		isHiddenCheck: true,
		titleQuestion: [
			{
				num: "5",
				title:
					"Complete the email using the correct form of the verbs.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
                <div style="position: relative">
                  <div><img style="width:51%" src='img/FriendsPlus/Page59/E2/1.jpg' /></div>
                  <div style="position: absolute; left: 37px; top: 70px; font-size: 18px; line-height: 120%;">
											Hi Samira!<br/>
											How are you? <u style="text-decoration: dotted underline; color: grey"><i><b>I'm having</b></i>&emsp;</u> (have) a <br/>
											wonderful time in New York with my family and I<br/>
											<sup>1</sup> <input id='0' width='90px'> (go) to all the famous places here. <br/>
											My dad <sup>2</sup> <input id='1' width='110px'> (enjoy) the trip – it’s his <br/>
											first holiday for a long time!<br/>
											We <sup>3</sup> <input id='2' width='140px'> (not stay) in a hotel. We <br/>
											<sup>4</sup> <input id='3' width='120px'> (visit) some American friends of <br/>
											my parents called Natalie and Brad. They <br/>
											<sup>5</sup> <input id='4' width='60px'> (live) in a nice apartment near <br/>
											Central Park. Brad <sup>6</sup> <input id='5' width='90px'> (teach) at <br/>
											one of the colleges here and he <sup>7</sup> <input id='6' width='70px'><br/>
											(walk) to work through the park every day. That’s <br/>
											cool! Natalie <sup>8</sup> <input id='7' width='120px'> (not go) out to <br/>
											work. She <sup>9</sup> <input id='8' width='80px'> (work) from home – <br/>
											she <sup>10</sup> <input id='9' width='70px'> (use) a small office in the <br/>
											apartment.<br/>
											What <sup>11</sup> <input id='10' width='140px'> (you / do) now? <br/>
											<sup>12</sup> <input id='11' width='150px'> (you / work) hard for the exams <br/>
											next month?<br/>
											See you soon,<br/>
											Alice<br/>
									</div>
                </div>
          `,
				answer: [
					"'m going ",
					"is enjoying",
					" aren't staying ",
					"'re visiting ",
					"live",
					"teaches",
					"walks",
					"doesn't go ",
					"works",
					"uses",
					"are you doing",
					"Are you working",
				],
			},
		],
	},

}

export default json;