import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: "Unit 3",
    id: "WB7-U3-P21-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page23/E1/Key/answerKey.png",
    maxLength: 7,

    textAlign: "center",
    hintBox: [{
      src: [
        "Did (x2)",
        "did",
        "didn't (x2) ",
        "enjoy",
        "<s>watched</s>",
      ],
      width: "100%",
      borderColor: "#77cedf",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the text with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div style="position: relative; margin: 22px">
            <div><img src='img/FriendsPlus/Page23/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 188px; left: 142px; "><input id='0' /></div>
              <div style=" position: absolute; top: 303px; left: 142px; "><input id='1' /></div>
              <div style=" position: absolute; top: 390px; left: 142px; "><input id='2' /></div>
              <div style=" position: absolute; top: 290px; left: 398px; "><input id='3' /></div>
              <div style=" position: absolute; top: 533px; left: 122px; "><input id='4' /></div>
              <div style=" position: absolute; top: 533px; left: 360px; "><input id='5' /></div>

            </div>
          </div>
        `,
        answer: [" didn't", "Did", "Did", "enjoy", "did", "didn't"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB7-U3-P23-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    hintBox: [{
      src: [
        "<s>not come</s>",
        "not eat",
        "not go",
        "help see",
        "stay",
        "not watch",

      ],
      borderColor: "#69d2e4",
      width: "100%"
    }],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page23/E2/Key/answerKey.png",
    textAlign: 'center',
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences using the affirmative or negative past simple form of the verbs.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div>Alex was ill on Thursday and he  <u style="text-decoration: dotted underline;">&emsp;<b>didn't come</b>&emsp;</u> to basketball club.</div>
                <div><b>1</b> We # our mum with the housework because she was very busy.</div>
                <div><b>2</b> The weather was awful yesterday so we # to the beach.</div>
                <div><b>3</b> I wasn't very hungry at lunch so I # a lot of food</div>
                <div><b>4</b> I # my friend Selin at the concert on Friday and she said hello.</div>
                <div><b>5</b> That TV programme was terrible so I # the end of it</div>
                <div><b>6</b> Dan # at home on Sunday because he was really tired.</div>
                <div style="text-align: center"><img style="width:50%" src='img/FriendsPlus/Page23/E2/1.jpg'/></div>
              </div>
          `,
        answer: ["helped", "didn't go", "didn't eat", "saw", "didn't watch", "stayed"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB7-U3-P23-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the dialogue using the past simple form of the verbs and short answers.",
        color: "#69d2e4",
        star: 2
      },
    ],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page23/E3/Key/answerKey.png",

    questions: [
      {
        title: `
          <div style="position: relative;">
            <div style="text-align: center"><img style="width:30%" src='img/FriendsPlus/Page23/E3/1.jpg' /></div>
            <div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Lucy</b></div> <div>What <u style="text-decoration: dotted underline;">&emsp;<b>did</b>&emsp;</u>you <u style="text-decoration: dotted underline;">&emsp;<b>do</b>&emsp;</u> (do) on Saturday?</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Anna</b></div> <div>I <sup>1</sup> # (meet) my cousin Emma in town.</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Lucy</b></div> <div><sup>2</sup> # you # (have) a nice time?</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Anna</b></div> <div>Yes, I <sup>3</sup> # . We <sup>4</sup> # (go) to the new shopping centre and I <sup>5</sup> #(get) a lovely present for my dad’s birthday.</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Lucy</b></div> <div><sup>6</sup> # you #(go) to any other places after that?</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Anna</b></div> <div>No, I <sup>7</sup> # . I <sup>8</sup> # (come) home on the bus. But I <sup>9</sup> # (leave) the bus very quickly and I <sup>10</sup> #(not take) my shopping bag with the present in it! It was under the seat and I <sup>11</sup> # (not see) it!</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Lucy</b></div> <div>Oh no! What <sup>12</sup> # you #(do)?</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Anna</b></div> <div>My mum <sup>13</sup> # (take) me to the bus station in the car. I <sup>14</sup> # (find) the same bus and the driver <sup>15</sup> # (give) me back my bag</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Lucy</b></div> <div>That was lucky!</div></div>
            </div>
          </div>
        `,
        answer: [
          "met", " Did", "have", "did", "went", "got", "Did", "go", "didn't", "came", "left", "didn't take", "didn't see", "did", "do", "took", "found", "gave"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB7-U3-P23-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "4",
        title:
          "Write a dialogue about what you did after school yesterday",
        color: "#69d2e4",
        star: 3
      },
    ],
    inputSize: 450,
    hideBtnFooter: true,
    questions: [
      {
        title: `
            <div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>Your friend</b></div> <div style="text-decoration: dotted-underline"> What did you do after school yesterday?</div></div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>You</b></div> <div>#</div></div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>Your friend</b></div> <div>#</div></div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>You</b></div> <div>#</div></div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>Your friend</b></div> <div>#</div></div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>You</b></div> <div>#</div></div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>Your friend</b></div> <div>#</div></div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>You</b></div> <div>#</div></div>
              <div style="display: flex"><div style="width: 200px;margin-right: 40px"><b>Your friend</b></div> <div>#</div></div>
            </div>
        `,
        answer: [],
      },
    ]
  }
}

export default json;