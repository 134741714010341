import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB7-U4-P26-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page26/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Choose the correct words",
        color: "#1d71a9",
        star: 1
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "jumping_up / standing",
          "hanging_from / sitting_on ",
          "climbing_up / walking_on ",
          "picking_up / climbing_up",
          "kicking_/ jumping_up",
        ],
        answers: ['0-0', '1-0', '2-4', '3-0', '4-0'],
        initialValue: [],
      },
      Layout: `
              <div style="display: flex; margin-bottom: 10px">
                <div style="margin-right: 20px">
                  <img src="img/FriendsPlus/Page26/E1/1.jpg"/>
                </div>
                <div>
                  <img src="img/FriendsPlus/Page26/E1/2.jpg"/>
                </div>
              </div>
              <div>
                <div>I'm   <b> lying on</b> / <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">holding</b>  the cup!<div>
                <div><b>1.</b> They're <b><input id='0' type='Circle' /></b> in the air.</div>
                <div style="display: flex; margin-bottom: 10px">
                  <div style="margin-right: 20px">
                    <img src="img/FriendsPlus/Page26/E1/3.jpg"/>
                  </div>
                  <div>
                    <img src="img/FriendsPlus/Page26/E1/4.jpg"/>
                  </div>
                </div>
                <div><b>2.</b> He’s <b><input id='1' type='Circle' /></b> the tree.</div>
                <div><b>3.</b> She’s <b><input id='2' type='Circle' /></b> the rope.</div>
                <div style="display: flex; margin-bottom: 10px">
                  <div style="margin-right: 20px">
                    <img src="img/FriendsPlus/Page26/E1/5.jpg"/>
                  </div>
                  <div>
                    <img src="img/FriendsPlus/Page26/E1/6.jpg"/>
                  </div>
                </div>
                <div><b>4.</b> The mother’s <b><input id='3' type='Circle' /></b> the toy.</div>
                <div><b>5.</b> Oh no! He isn’t <b><input id='4' type='Circle' /></b> the ball</div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "WB7-U4-P26-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    hintBox: [{
      src: [
        "<s>hang from</s>",
        "hold",
        "kick ",
        "stand in",
        "walk on",
      ],
      borderColor: "#1d71a9",
      width: "60%"
    }],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page26/E2/Key/answerKey.png",
    textAlign: 'center',
    titleQuestion: [
      {
        num: "2",
        title:
          `Complete the dialogues using the -ing form of the verbs.`,
        color: "#1d71a9",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div> Are your cousins in that photo?’ ‘Yes, they’re at the front. They’re  <u style="text-decoration: dotted underline;">&emsp;<b>hanging from</b>&emsp;</u> the tree.</div>
                <div><b>1</b> ‘Are Jim and Robbie here?’ ‘No, they’re # the beach at the moment.’</div>
                <div><b>2</b>  ‘Is your sister playing in the football match?’ ‘Yes, she’s # the ball now.’</div>
                <div><b>3</b> ‘Be careful! There’s some food on the floor.’ ‘Oh no! I’m # the middle of it.’</div>
                <div><b>4</b> I like the film about that young musician.’ ‘Yes, it’s really good when he wins the competition and he’s # the prize in the end.’</div>
              </div>
          `,
        answer: ["walking on", "kicking", "standing in", "holding"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB7-U4-P26-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page26/E3/Key/answerKey.png",
    maxLength: 16,

    textAlign: "center",
    hintBox: [{
      src: [
        "climb up",
        "hang from",
        "<s>hold</s>",
        "hold ",
        "sit on",
        "not stand",
      ],
      width: "100%",
      borderColor: "#1d71a9",
    }],
    titleQuestion: [
      {
        num: "3",
        title:
          " Complete the text with the present continuous form of the verbs in the box.",
        color: "#1d71a9",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page26/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 469px; left: 44px; "><input id='0' /></div>
              <div style=" position: absolute; top: 497px; left: 44px; "><input id='1' /></div>
              <div style=" position: absolute; top: 653px; left: 44px; "><input id='2' /></div>
              <div style=" position: absolute; top: 707px; left: 44px; "><input id='3' /></div>
              <div style=" position: absolute; top: 763px; left: 44px; "><input id='4' /></div>
            </div>
          </div>
        `,
        answer: [" 're sitting on", " aren't standing", "'re climbing up", "'re hanging from", "'s holding "]
      }
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB7-U4-P26-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          " Think of two of your favourite photos and describe them.Use some of the verbs from this page.",
        color: "#1d71a9",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div style="color: grey; font-weight: bold">In the foreground of the photo you can see my cousin Amelia and my sister Chloe ...</div>
          <div>
            <div style="display: flex"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>1</b></div><div><textarea id="0" rows="6"></textarea></div></div>
            <div style="display: flex"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>2</b></div><div><textarea id="1" rows="6"></textarea></div></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;