import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB7-U2-P17-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 105,
    exerciseKey: "img/FriendsPlus/Page17/E1/Key/answerKey.png",
    maxLength: 9,

    textAlign: "center",
    hintBox: [
      {
        src: [
          "am",
          "<s>Am</s>",
          "are",
          "Are",
          "aren't",
          "is",
          "Is",
          "isn’t",
          "listening",
          "’m not",
        ],
        width: "87%",
        borderColor: "#77cedf",
      },
    ],
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Complete the table with the words.",
        color: "#69d2e4",
        star: 1,
      },
    ],
    questions: [
      {
        title: `
          <div style="padding: 20px 0px"><hintbox id="0"></hintbox></div>
          <div style="position: relative;">
            <div><img style="width:87%" src='img/FriendsPlus/Page17/E1/1.jpg' /></div>
            <div>
              <div style="position: absolute; top: 144px; left: -1px;"><input id='0' /></div>
              <div style="position: absolute; top: 179px; left: 1px;"><input id='0' /></div>
              <div style="position: absolute; top: 142px; left: 312px;"><input id='0' /></div>
              <div style="position: absolute; top: 308px; left: 60px;"><input id='0' /></div>
              <div style="position: absolute; top: 343px; left: 170px;"><input id='0' /></div>
              <div style="position: absolute; top: 409px; left: 65px;"><input id='0' /></div>
              <div style="position: absolute; top: 308px; left: 340px;"><input id='0' /></div>
              <div style="position: absolute; top: 343px; left: 460px;"><input id='0' /></div>
              <div style="position: absolute; top: 378px; left: 500px;"><input id='0' /></div>
            </div>
          </div>
        `,
        answer: [
          "Is",
          "Are",
          "listening",
          "am",
          "is",
          "are",
          "'m not",
          "isn't",
          "aren't",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB7-U2-P17-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 700,
    exerciseKey: "img/FriendsPlus/Page17/E2/Key/answerKey.png",
    hintBox: [
      {
        src: [
          "at school",
          "<s>at the shops</s>",
          "in bed",
          "in front of the TV",
          "in the countryside ",
          "on the bus",
          "on the phone",
        ],
        width: "61%",
        borderColor: "#69d2e4",
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title:
          "  Write questions using the present continuous. Then write the short answers.",
        color: "#69d2e4",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; width: 1500px">
            <div style="margin-right: 40px"><img src='img/FriendsPlus/Page17/E2/1.jpg' /></div>
            <div>
              <div>Ricky / play the guitar ? <u style="text-decoration: dotted underline;">&emsp;<i><b>Is Ricky playing the guitar? Yes, he is.</b></i>&emsp;</u></div>
              <div><b>1</b>  Mum / paint / a picture ?<br /><input id='0' /></div>
              <div><b>2</b> Eva / read / a magazine ?<br /><input id='1' /></div>
              <div><b>3</b> Freddie and Ellen / bake / cakes ?<br /><input id='2' /></div>
              <div><b>4</b>  Ricky and Will / sit / on the sofa ?<br /><input id='3' /></div>
              <div><b>5</b> Will / make / a video of Ricky ?<br /><input id='4' /></div>
              <div><b>6</b> Eva and Mum / have a conversation ?<br /><input id='5' /></div>
            </div>
          </div>
        `,
        answer: [
          "Is Mum painting a picture? Yes, she is.",
          "Is Eva reading a magazine? No, she isn't.",
          "Are Freddie and Ellen baking cakes? Yes, they are.",
          "Are Ricky and Will sitting on the sofa? No, they aren't.",
          "Is Will making a video of Ricky? Yes, he is.",
          "Are Eva and Mum having a conversation? No, they aren't.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB7-U2-P17-E3",
    component: T6,
    inputSize: "170px",
    exerciseKey: "img/FriendsPlus/Page17/E3/Key/answerKey.png",

    maxLength: 13,
    hintBox: [
      {
        src: [
          "bake",
          "not do",
          "get up",
          "not go",
          "have",
          "play",
          "<s>study</s>",
        ],
        borderColor: "#69d2e4",
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the sentences using the present simple or present continuous form of the verbs.",
        color: "#69d2e4",
        star: 2,
      },
    ],
    stylesTextInput: { textAlign: "center" },
    questions: [
      {
        title: `
        <div>
          <div>
            <div style="display: flex; justify-content: center">
              <hintbox id="0"></hintbox>
            </div>
          <div>
            <div style="color: grey;">Martin’s busy. He<b> 's studying</b> for that important exam next week</div>
            <div><b>1</b>. Lukas and Vadim # basketball at the sports centre right now. It’s a very important match.</div>
            <div><b>2</b>. I always # at 7:00 in the morning.</div>
            <div><b>3</b>. What time # you # dinner every evening?</div>
            <div><b>4</b>. We # to school in the holidays.</div>
            <div><b>5</b>. My brother is on his mobile phone again! He # his homework.</div>
            <div><b>6</b>.  Where’s Rachel? # she # some cakes in the kitchen?</div>
            </div>
          </div>
        </div>`,
        answer: [
          "are playing",
          "get up ",
          "do",
          "have",
          "don't go",
          "isn't doing",
          "Is",
          "baking",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB7-U2-P17-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 700,
    exerciseKey: "img/FriendsPlus/Page17/E4/Key/answerKey.png",
    hintBox: [
      {
        src: [
          "at school",
          "<s>at the shops</s>",
          "in bed",
          "in front of the TV",
          "in the countryside ",
          "on the bus",
          "on the phone",
        ],
        width: "61%",
        borderColor: "#69d2e4",
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title:
          "  Write questions using the present continuous. Then write the answers that are true for you..",
        color: "#69d2e4",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
          <div>
            <div>
              <div>what / you / usually do / on Saturday morning ? <u style="text-decoration: dotted underline; color: grey"><i><br>What do you usually do on Saturday morning?</br> I often go online or I sometimes meet my friends.</b></i>&emsp;</u></div>
              <div><b>1</b>  what / you / do / now ?<br /><input id='0' /><br /><input id='0' /></div>
              <div><b>2</b> what / your mum / often do / at the weekend ?<br /><input id='1' /><br /><input id='0' /></div>
              <div><b>3</b> what / your best friend / do / at the moment  ?<br /><input id='2' /><br /><input id='0' /></div>
              <div><b>4</b>  where / your friends / go / after school every day ?<br /><input id='3' /><br /><input id='0' /></div>
              <div><b>5</b> Will / make / a video of Ricky ?<br /><input id='4' /><br /><input id='0' /></div>
              <div><b>6</b> where / you / sit / right now ?<br /><input id='5' /><br /><input id='0' /></div>
            </div>
          </div>
        `,
        answer: [
          "What are you doing now?",
          "",
          "What does your mum often do at the weekend?",
          "",
          "Where do you usually do your homework?",
          "",
          "What is your best friend doing at the moment?",
          "",
          "Where do your friends go after school every day?",
          "",
          "Where are you sitting right now?",
          "",
        ],
      },
    ],
  },
};

export default json;
