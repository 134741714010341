import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
  1: {
    unit: "Unit 7",
    id: "WB7-U7-P44-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page44/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Which word is the odd one out?",
        color: "#0167B4",
        star: 1
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          margin: 50,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          margin: 50,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "violin concert guitar piano", //0
          "song hit lyrics traditional", //1
          "fan hard_rock hip-hop heavy_metal ", //0
          "rhythm beat catchy music", //1
          "bass classical guitar drums ", //0
          " salsa samba DJ rumba  ", //1
        ],
        answers: ['2-0', '4-2', '0-2', '1-6', '3-4', '5-6'],
        initialValue: [],
      },
      Layout: `
              <div>
                <div> rock &emsp;&emsp;rap &emsp;&emsp;<b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">vocals</b>  &emsp;&emsp;pop<div>
                <div><b>1.</b><input type='Circle' id='0'/></div>
                <div><b>2.</b><input type='Circle' id='1'/></div>
                <div><b>3.</b><input type='Circle' id='2'/></div>
                <div><b>4.</b><input type='Circle' id='3'/></div>
                <div><b>5.</b><input type='Circle' id='4'/></div>
                <div><b>6.</b><input type='Circle' id='5'/></div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "WB7-U7-P44-E2",
    component: T6,
    questionImage: [],
    exerciseKey: "img/FriendsPlus/Page44/E2/Key/answerKey.png",
    stylesTextInput: { textAlign: 'left', marginInline: '1px', border: "none" },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the words.",
        color: "#0167B4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div style="position: relative;">
                <div> Children like listening to <b>c</b> <u style="color: grey; margin-inline: 3px"><b>a</b></u><u style="color: grey; margin-inline: 3px"><b>t</b></u><u style="color: grey; margin-inline: 3px"><b>c</b></u><u style="color: grey; margin-inline: 3px"><b>h</b></u><u style="color: grey; margin-inline: 3px"><b>y</b></u>  songs.</div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>1. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> When Liam plays the <b>d</b> _ _ _ _ he makes a lot of noise! He hits them very hard.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>2. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> We like <b>t</b> _ _ _ _ _ _ _ _ _ _  music from our country. It isn't very modern, but it's got a good rhythm.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>3. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> She wants to be a <b>p</b> _ _ singer like Mỹ Tâm. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>4. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> My brother plays the <b>g</b> _ _ _ _ _ in a band.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>5. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> Her favourite music is by Run-DMC, a <b>r</b> _ _ _  group.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>6. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> <b>S</b> _ _ _ _ is Brazilian music. You can dance to it.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>7. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> He's practising hard for his <b>p</b> _ _ _ _ exam.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>8. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> This song was a big <b>h</b> _ _ for the band last year.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>9. </b></div>
                  <div style="margin-left: 30px; flex: 1; "> A band played <b>s</b> _ _ _ _ , and spectators danced wildly.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>10. </b></div
                  ><div style="margin-left: 30px; flex: 1; "> We love loud music like heavy metal and <b>r</b> _ _ _ </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>11. </b></div
                  ><div style="margin-left: 30px; flex: 1; "> Paul Simon writes the <b>l</b> _ _ _ _ _ for most of his songs.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;">
                  <b>12. </b></div
                  ><div style="margin-left: 30px; flex: 1; "> Grace likes her guitar with six strings, but she wants a <b>b</b> _ _ _ guitar too.</div></div>

              <div style="position: absolute; top: 37px; left: 308px;"><input id='0' width="100px"/></div>
              <div style="position: absolute; top: 75px; left: 157px;"><input id='1' width="180px"/></div>
              <div style="position: absolute; top: 113px; left: 280px;"><input id='2' width="60px"/></div>
              <div style="position: absolute; top: 152px; left: 302px;"><input id='3' width="110px"/></div>
              <div style="position: absolute; top: 189px; left: 480px;"><input id='4' width="80px"/></div>
              <div style="position: absolute; top: 226px; left: 79px;"><input id='5' width="105px"/></div>
              <div style="position: absolute; top: 264px; left: 366px;"><input id='6' width="90px"/></div>
              <div style="position: absolute; top: 302px; left: 300px;"><input id='7' width="50px"/></div>
              <div style="position: absolute; top: 340px; left: 235px;"><input id='8' width="100px"/></div>
              <div style="position: absolute; top: 377px; left: 510px;"><input id='9' width="80px"/></div>
              <div style="position: absolute; top: 415px; left: 310px;"><input id='10' width="100px"/></div>
              <div style="position: absolute; top: 452px; left: 655px;"><input id='11' width="80px"/></div>
              </div>
          `,
        answer: [
          "rums",
          "raditional",
          "op",
          "uitar",
          "ock",
          "amba",
          "iano",
          "it",
          "alsa",
          "ock",
          "yrics",
          "ass",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "WB7-U7-P44-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 16 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page44/E3/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "band",
        "classical",
        "concerts",
        "fan",
        "heavy ",
        "hip-hop",
        "lyrics ",
        "<s>pop</s>",
        "rock",
        "violin",
        "vocals",
      ],
      width: "450px",
      borderColor: "#0167B4",
    }],
    titleQuestion: [
      {
        num: "3",
        title:
          " Complete the text with the words.",
        color: "#0167B4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page44/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 69px; left: 158px; "><input id='0' /></div>
              <div style=" position: absolute; top: 150px; left: 74px; "><input id='1' /></div>
              <div style=" position: absolute; top: 150px; left: 223px; "><input id='2' /></div>
              <div style=" position: absolute; top: 230px; left: -22px; "><input id='3' /></div>
              <div style=" position: absolute; top: 270px; left: 90px; "><input id='4' /></div>
              <div style=" position: absolute; top: 367px; left: 107px; "><input id='5' /></div>
              <div style=" position: absolute; top: 387px; left: 109px; "><input id='6' /></div>
              <div style=" position: absolute; top: 407px; left: 134px; "><input id='7' /></div>
              <div style=" position: absolute; top: 503px; left: -14px; "><input id='8' /></div>
              <div style=" position: absolute; top: 545px; left: 118px; "><input id='9' /></div>
            </div>
          </div>
        `,
        answer: [
          "band",
          "rock",
          "heavy",
          "fan",
          "hip-hop",
          "violin",
          "concerts",
          "classical",
          "vocals",
          "lyrics",
        ]
      }
    ],
  },
  4: {
    unit: "Unit 7",
    id: "WB7-U7-P44-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [{
      src: [
        "dance",
        "listen to",
        "play",
        "sing",
        "watch",
      ],
      borderColor: "#0167B4",
      width: "100%"
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          " Write about your favourite music. Use some of the words in the box and in exercise 3 to help you.",
        color: "#0167B4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; justify-content: center"><hintbox id="0"></hintbox></div>
          <b>My favourite music</b>
          <div><textarea id="0" rows="11">I really like listening to hip-hop and I love dancing to cool electronic music.</textarea></div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;
