import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: "Unit 8",
		id: "WB7-U8-P54-E1",
		component: T6,
		questionImage: [],
		inputSize: 320,
		exerciseKey: 'img/FriendsPlus/Page54/E1/Key/answerKey.png',
		textareaStyle: { resize: "none", borderBottom: "none" },

		stylesTextInput: { marginInline: '10px', fontSize: 20, textAlign: 'center' },
		titleQuestion: [
			{
				num: "1",
				title:
					"Complete the table with <i>Fossil fuels</i> or <i>Alternative energy sources</i>.",
				color: "#dc2c39",
				star: 1
			},
		],
		hintBox: [{
			src: [
				"because",
				"and",
				"but",
				"or",
				"so",
				"although",
			],
			width: "558px",
			borderColor: "#dc2c39",
		}],
		questions: [
			{
				title: `
              <div>
									<div style=" text-align: center; margin-block: 10px"><img style="width: 73%" src="img/FriendsPlus/Page54/E1/1.jpg"/></div>
									<div style="display: flex; justify-content: center; font-size: 20px">
										<div style="border: 1px solid rgb(218, 43, 55); border-radius: 20px; overflow: hidden; width: fit-content">
											<tr style="background-color: rgb(243, 191, 175); font-weight: bold; text-align: center">
												<td style="padding: 10px; border: 1px solid rgb(218, 43, 55)"><b>1</b> # </td>
												<td style="padding: 10px; border: 1px solid rgb(218, 43, 55)"><b>2</b> # </td>
											</tr>
											<tr>
												<td style="border: 1px solid rgb(218, 43, 55); text-align: center">sea wave</br>wind power</br> hydropower</br> …</td>
												<td style="border: 1px solid rgb(218, 43, 55); text-align: center">coal </br>petrol </br>DO </br>gas</br> …</td>
											</tr>
										</div>
									</div>
              </div>
          `,
				answer: [
					" Alternative energy sources",
					"Fossil fuels",
				],
			},
		],
	},
	2: {
		unit: "Unit 8",
		id: "WB7-U8-P54-E2",
		component: T6,
		questionImage: [],
		inputSize: 150,
		exerciseKey: "img/FriendsPlus/Page54/E2/Key/answerKey.png",

		stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
		titleQuestion: [
			{
				num: "2",
				title:
					"Read the article. Complete the sentences with the words from the article.",
				color: "#dc2c39",
				star: 2
			},
		],
		questions: [
			{
				title: `
              <div>
								<div style=" text-align: center; margin-block: 10px"><img style="width: 74%" src="img/FriendsPlus/Page54/E1/1.jpg"/></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">Alternative energy sources are not the result of of # fossil fuels</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> # fuels make use of daily waste products</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Besides generating electricity, geothermal energy also # homes.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> # contain both thermal energy and mechanical energy.</div></div>
              </div>
          `,
				answer: [
					"burning",
					"Biomass",
					"heats",
					"Oceans",
				],
			},
		],
	},

	3: {
		unit: "Unit 5",
		id: "WB7-U5-P54-E3",
		component: T6,
		questionImage: [],
		stylesTextInput: { fontSize: 24 },
		inputSize: 105,
		exerciseKey: "img/FriendsPlus/Page54/E3/Key/answerKey.png",
		maxLength: 1,

		textAlign: "center",
		titleQuestion: [
			{
				num: "3",
				title:
					" Read the article again. Write true or false.",
				color: "#dc2c39",
				star: 2
			},
		],
		questions: [
			{
				title: `
        <div style="display: flex">
          <div>
						<div style=" text-align: center; margin-block: 10px"><img style="width: 74%" src="img/FriendsPlus/Page54/E1/1.jpg"/></div>
            <div><b>1.</b> We must burn fossil fuels to get energy.  <input id='0' /></div>
            <div><b>2.</b> Biomass fuels don’t include farm products <input id='1' /></div>
            <div><b>3.</b> They also use geothermal energy to heat homes in the capital of Iceland.    <input id='2' /></div>
            <div><b>4.</b> Only tides in oceans can produceelectricity.    <input id='3' /></div>
          </div>
			  </div>
        `,
				answer: ["true", "false", "true", "false"],
			},
		],
	},

	4: {
		unit: "Unit 8",
		id: "WB7-U8-P54-E4",
		component: T6,
		questionImage: [],
		inputSize: 250,
		exerciseKey: "img/FriendsPlus/Page54/E4/Key/answerKey.png",

		stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
		titleQuestion: [
			{
				num: "4",
				title:
					"Complete the sentences. Choose NO MORE THAN THREE WORDS from the article for each answer.",
				color: "#dc2c39",
				star: 3
			},
		],
		questions: [
			{
				title: `
              <div>
								<div style=" text-align: center; margin-block: 10px"><img style="width: 74%" src="img/FriendsPlus/Page54/E1/1.jpg"/></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">Alternative energy sources are usually # because we do not burn them.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> #  include grains, agricultural crops and aquatic plants</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> They can make use of the heat from # to generate </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> OTEC changes # into electricity.</div></div>
              </div>
          `,
				answer: [
					"less polluting ",
					"Biomass fuels ",
					"within the earth",
					"solar radiation",
				],
			},
		],
	},
}

export default json;