import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {

  1: {
    unit: "Unit 3",
    id: "WB7-U3-P24-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page24/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the text. Choose the correct words.",
        color: "#dc2c39",
        star: 1
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "Italian / French ", //0
          "small / large", //1
          "cave / building", //2
        ],
        answers: ["0-4", "1-4", "2-0"],
        initialValue: [],
      },
      Layout: `
              <div>
                <div>The story is about some young <b><input id='0' type='Circle' /></b> people. </div>
                <div>They found a  <b><input id='1' type='Circle' /></b> number of interesting things in a  <b style="display: inline-block"><input id='2' type='Circle' /></b> near their home.</div>
                <div style="margin: 15px; text-align:center "><img style="width:80%" src="img/FriendsPlus/Page24/E1/1.jpg"/></div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "WB7-U3-P24-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 20, textTransform: 'uppercase' },
    inputSize: 50,
    exerciseKey: "img/FriendsPlus/Page24/E2/Key/answerKey.png",
    maxLength: 1,
    textAlign: "center",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the text again. Match paragraphs A–E with phrases 1–4.",
        color: "#dc2c39",
        star: 2
      },
    ],

    questions: [
      {
        title: `
        <div style="display: flex">
          <div><img style="width:80%" src="img/FriendsPlus/Page24/E1/1.jpg"/></div>
          <div style="width: 900px"">
            <div>A young man finds something strange  <b style="color: grey"><i> A </i></b></div>
            <div><b>1</b> A big surprise in the cave <input id='0' /></div>
            <div><b>2</b> An important person comes to Montignac   <input id='1' /></div>
            <div><b>3</b> Marcel returns with some others   <input id='2' /></div>
            <div><b>4</b> The boys tell their story  <input id='3' /></div>
          </div>
			  </div>
        `,
        answer: ["C", "E", "B", "D",],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB7-U3-P24-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 40,
    exerciseKey: "img/FriendsPlus/Page24/E3/Key/answerKey.png",
    maxLength: 1,

    textAlign: "center",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text again. Put the story in the correct order. Number the sentences 1–8.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div style="text-align: center"><img style="width:80%" src="img/FriendsPlus/Page24/E1/1.jpg"/></div>
              <div style="padding-top: 15px">
                <tr>
                  <td style="padding: 0px 30px;"><b> a </b></td>
                  <td style=" border: 1px solid black; width: 40px; height: 40px">#</td>
                  <td style="padding-left: 40px">The boys went down the tunnel into a cave. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> b </b></td>
                  <td style=" border: 1px solid black; text-align: center; color: grey; font-weight: bold">1</td>
                  <td style="padding-left: 40px"> Marcel Ravidat was in the woods one day. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> c </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> There were plants over the hole. The boys took them away. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> d </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px">The boys talked to their teacher. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> e </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Marcel saw a hole in the ground in the woods. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> f </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> The boys found a lot of pictures </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> g </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> A famous person talked to the boys about the pictures. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> h </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Marcel went back to the hole with friends. </td>
                </tr>
              </div>
            </div>
        `,
        answer: [
          "5",
          "4",
          "7",
          "2",
          "6",
          "8",
          "3",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB7-U3-P24-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 1250,
    exerciseKey: "img/FriendsPlus/Page24/E4/Key/answerKey.png",

    titleQuestion: [
      {
        num: "4",
        title:
          "Answer the questions. Write complete sentences.",
        color: "#d01435",
        star: 3
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>Where is Montignac? </div>
                <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b>It's in France.</b>&emsp;</u></div>
                <div><b>1.</b> What stories were there in Montignac?</div>
                <div>#</div>
                <div><b>2.</b> What colours were the pictures?</div>
                <div>#</div>
                <div><b>3.</b> How many pictures were there? How old were they?</div>
                <div>#</div>
                <div><b>4.</b> Why were the pictures very important? </div>
                <div>#</div>
                <div><b>5.</b>How do you think the boys felt after they spoke to the historian?  </div>
                <div>#</div>
              </div>
          `,
        answer: [
          "There were stories about an old tunnel with treasure in it.",
          "The pictures were red, brown and black.",
          "There were about 1,500 pictures. They were over 17, 000 years old.",
          "They were important because they were very old and the boys were the first modern people to see them.",
          "",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB7-U3-P24-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page24/E5/Key/answerKey.png",
    textAlign: 'center',
    titleQuestion: [
      {
        num: "5",
        title:
          "<b style='color: #d01435;'>VOCABULARY PLUS </b> Complete the words in the text.",
        color: "#d01435",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div style="margin-top: 50px">
                Last week I visited London with my family. My favourite place was the Natural History <sup>1</sup><b>M</b> # – it has got a <sup>2</sup><b>c</b> # of dinosaurs! My favourite <sup>3</sup><b>e</b> # was the enormous dinosaur called ‘Dippy’. We also went to the Victoria and Albert Museum. It’s in a beautiful, old <sup>4</sup><b>b</b> #  , but my sister wanted to see the <sup>5</sup><b>e</b> # of shoes. It was so boring!
              </div>
          `,
        answer: [" Museum", "collection", "exhibit", "building", "exhibition"],
      },
    ],
  },

}

export default json;