import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB7-U2-P15-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 85,
    exerciseKey: "img/FriendsPlus/Page15/E1/Key/answerKey.png",
    maxLength: 7,

    isHiddenCheck: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the table with am, are or is and the -ing form of the verbs. Make contractions where possible.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div><img  src='img/FriendsPlus/Page15/E1/1.jpg' /></div>
            <div>
              <div style="position: absolute; top: 110px; left: 220px;"><input id='0' /></div>
              <div style="position: absolute; top: 144px; left: 220px;"><input id='0' /></div>
              <div style="position: absolute; top: 253px; left: 220px;"><input id='0' /></div>
              <div style="position: absolute; top: 286px; left: 220px;"><input id='0' /></div>
              <div style="position: absolute; top: 318px; left: 220px;"><input id='1' /></div>
              <div style="position: absolute; top: 98px; left: 415px;"><input id='2' /></div>
              <div style="position: absolute; top: 133px; left: 415px;"><input id='2' /></div>
              <div style="position: absolute; top: 167px; left: 415px;"><input id='2' /></div>
              <div style="position: absolute; top: 253px; left: 415px;"><input id='2' /></div>
              <div style="position: absolute; top: 286px; left: 415px;"><input id='2' /></div>
              <div style="position: absolute; top: 317px; left: 415px;"><input id='2' /></div>
            </div>
          </div>
        `,
        answer: ["'re", "'s", "'m", "are", "is", "talking", "sitting", "taking", "playing", "running", "eating"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB7-U2-P15-E2",
    component: T6,
    stylesTextInput: { fontSize: 25 },
    inputSize: 400,
    exerciseKey: "img/FriendsPlus/Page15/E2/Key/answerKey.png",

    hintBox: [{
      src: [
        "draw a picture",
        "have a conversation",
        "listen to music",
        "play volleyball",
        "read a book",
        "run",
        "send a text message",
        "<s>talk on the phone</s>",
        "watch a video",
      ],
      width: "60%",
      borderColor: "#77cedf",
    }],
    titleQuestion: [
      {
        num: "2",
        title:
          "Write sentences using the present continuous and the phrases.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div><hintbox id="0"></hintbox></div>
            <div style="margin: 10px"><img style="width:50%; margin-left: 60px" src='img/FriendsPlus/Page15/E2/1.jpg' /></div>
            <div>
              <div>Dan<b style="color: grey"> is talking on the phone.</b></div>
              <div><b>1</b> My grandmother  #</div>
              <div><b>2</b> Adam and Josh #</div>
              <div><b>3</b> Betty #</div>
              <div><b>4</b> Alfie  #</div>
              <div><b>5</b> My mum and dad  #</div>
              <div><b>6</b> Rory  #</div>
              <div><b>7</b>  My cousins #</div>
              <div><b>8</b> I  #</div>
            </div>
        `,
        answer: [
          " is sending a text message",
          " are playing volleyball",
          " is reading a book",
          " is listening to music",
          " are having a conversation",
          " is running",
          " are watching a video",
          " 'm drawing a picture",
        ]
      },
    ],
  },

  3: {
    unit: "Unit 2",
    id: "WB7-U2-P15-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 165,
    exerciseKey: "img/FriendsPlus/Page15/E3/Key/answerKey.png",
    maxLength: 7,

    textAlign: "center",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Write sentences using the negative form of the present continuous. Then match sentences 1–7 to speakers a–g",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; width: 1600px">
          <div style="position: relative; margin-right: 20px">
            <div><img src='img/FriendsPlus/Page15/E3/1.jpg' /></div>
            <div>
              <div style="position: absolute; top: 86px; left: 100px;"><input id='0' /></div>
              <div style="position: absolute; top: 162px; left: 60px;"><input id='1' /></div>
              <div style="position: absolute; top: 245px; left: 90px;"><input id='2' /></div>
              <div style="position: absolute; top: 334px; left: 100px;"><input id='3' /></div>
              <div style="position: absolute; top: 414px; left: 110px;"><input id='4' /></div>
              <div style="position: absolute; top: 502px; left: 140px;"><input id='5' /></div>
            </div>
          </div>
          <div>
              <tr>
                <td style="padding: 5px 30px;"><b> a </b></td>
                <td style="padding-right: 40px"> A teacher in the last lesson of the day </td>
                <td style=" border: 1px solid black; width: 40px; height: 40px"><input width='35px' id='6' /></td>
              </tr>
              <tr>
                <td style="padding: 5px 30px;"><b> b </b></td>
                <td> A worried driving instructor </td>
                <td style=" border: 1px solid black; text-align: center; color: grey; font-weight: bold">1</td>
              </tr>
              <tr>
                <td style="padding: 5px 30px;"><b> c </b></td>
                <td> Parents talking about their son and daughter at home </td>
                <td style=" border: 1px solid black"><input width='35px' id='7' /></td>
              </tr>
              <tr>
                <td style="padding: 5px 30px;"><b> d </b></td>
                <td> A student in a difficult exam </td>
                <td style=" border: 1px solid black"><input width='35px' id='8' /></td>
              </tr>
              <tr>
                <td style="padding: 5px 30px;"><b> e </b></td>
                <td>  A girl talking about her younger brother </td>
                <td style=" border: 1px solid black"><input width='35px' id='9' /></td>
              </tr>
              <tr>
                <td style="padding: 5px 30px;"><b> f </b></td>
                <td> A very famous singer </td>
                <td style=" border: 1px solid black"><input width='35px' id='10' /></td>
              </tr>
              <tr>
                <td style="padding: 5px 30px;"><b> g </b></td>
                <td> People eating in a five-star restaurant </td>
                <td style=" border: 1px solid black"><input width='35px' id='11' /></td>
              </tr>
            </div>
          </div>
        `,
        answer: [
          "aren't helping",
          " 'm not singing",
          "aren't listening",
          "aren't enjoying",
          "isn't eating",
          "'m not writing",
          "4",
          "2",
          "7",
          "6",
          "3",
          "5",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB7-U2-P15-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [{
      src: [
        "I",
        "my best friend",
        "my brother / sister",
        "my classmates",
        "my favourite actor",
        "my favourite footballer",
        "my favourite singer",
        "my parents",
      ],
      width: "100%",
      borderColor: "#77cedf",
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          "What are these people doing now? Write sentences using the present continuous.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div><textarea width="100%" id="0" rows="11">I think my brother is sending text messages to his bestfriend and he isn't studying!</textarea></div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;