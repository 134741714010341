import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB7-U1-P8-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page8/E1/Key/answerKey.png",
    // titleQuestion: [{ num: '3', title: 'Choose the correct words.', color: "#2CBAEA" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "at_school / on_the_phone.", //0
          "at_the_shops / in_her_room.", //1
          "the_car / the_countryside.", //2
          "bed / front_of_the_TV.", //3
          "on_the_bus / in_the_park.", //4
          "the_playground / a_fast_food_restaurant.", //5
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
        <div style="line-height: 48px;justify-content: space-between;">
          <div><img src='img/FriendsPlus/Page8/E1/1.jpg'/></div>
          <div>
            <div>He isn’t in class. He’s in the <b>car</b> / <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">playground.</b>
            <div><b>1</b> They aren’t at home. They’re <b><input id='0' type='Circle' /></b></div>
            <div><b>2</b> She isn’t on the bus. She’s <b><input id='1' type='Circle' /></b></div>
            <div><b>3</b> He isn’t in bed. He’s in <b><input id='2' type='Circle' /></b></div>
            <div><b>4</b> He isn’t on the sofa. He’s in <b><input id='3' type='Circle' /></b></div>
            <div><b>5</b> They aren’t at the shops. They’re <b><input id='4' type='Circle' /></b></div>
            <div><b>6</b> We aren’t at the park. We’re in <b><input id='5' type='Circle' /></b></div>
          </div>
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "WB7-U1-P8-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 300,
    exerciseKey: "img/FriendsPlus/Page8/E2/Key/answerKey.png",
    hintBox: [{
      src: [
        "at school",
        "<s>at the shops</s>",
        "in bed",
        "in front of the TV",
        "in the countryside ",
        "on the bus",
        "on the phone",
      ],
      width: "61%",
      borderColor: "#1d71a9",
    }],
    titleQuestion: [
      {
        num: "2",
        title:
          " Read the clues and write the words.",
        color: "#1d71a9",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div>
            <div><hintbox id="0"></hintbox></div>
            <div>
              <div>When you’re at the supermarket or shopping centre. <u style="text-decoration: dotted underline;">&emsp;<i><b>at the shops</b></i>&emsp;</u></div>
              <div><b>1</b> When you speak to somebody on your mobile.<br /><input id='0' /></div>
              <div><b>2</b> When you’re in your classroom<br /><input id='1' /></div>
              <div><b>3</b> When you’re asleep at night.<br /><input id='2' /></div>
              <div><b>4</b> When you travel with lots of people.<br /><input id='3' /></div>
              <div><b>5</b> When you watch a TV programme.<br /><input id='4' /></div>
              <div><b>6</b> When you aren’t in a town or city.<br /><input id='5' /></div>
            </div>
          </div>
        `,
        answer: [
          "on the phone",
          "at school",
          "in bed",
          "on the bus",
          "in front of the TV",
          "in the countryside",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB7-U1-P8-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page8/E3/Key/answerKey.png",

    inputSize: 300,
    titleImage: "",
    hintBox: [{
      src: [
        "fast-food restaurants",
        "<s>front of the TV</s>",
        "the park ",
        "his room",
        "the car",
        "the phone",
        "the shops",
      ],
      width: "61%",
      borderColor: "#1d71a9",
    }],
    titleQuestion: [
      {
        num: "3",
        title:
          " Complete the dialogues with the words in the box and in, at or on.",
        color: "#1d71a9",
        star: 2
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div>
          <div><hintbox id="0"></hintbox></div>
          <div>
            <div>‘Where’s Jess?’ ‘She’s <u style="text-decoration: dotted underline;">&emsp;<i><b>in front of the TV</b></i>&emsp;</u> again. It’s <i>The Simpsons</i>.’</div>
            <div><b>1</b> Dad’s <input id='0' /> We need lots of food for the party.</div>
            <div><b>2</b> ‘Is Ashraf in the garden?’ ‘No, he isn’t. He’s <input id='1' /> ’</div>
            <div><b>3</b> ‘Do you like eating <input id='2' /> ?’ ‘No, I hate burgers and chips!’</div>
            <div><b>4</b> ‘Why isn’t Harry on the bus today?’ ‘He’s <input id='3' /> with his mum.’</div>
            <div><b>5</b> ‘Where’s your younger brother?’ ‘He’s <input id='4' /> next to our house. He’s in the new playground there.’</div>
            <div><b>6</b> ‘Is Lucy busy?’ ‘Yes, listen. She’s <input id='5' /> ’</div>
          </div>
        </div>
          `,
        answer: [
          "at the shops",
          "in his room",
          "in fast-food restaurants",
          "in the car",
          "in the park",
          "on the phone",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P8-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          " Where are you at these different times?",
        color: "#1d71a9",
        star: 3
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>After school every day,<br /><u style="text-decoration: dotted underline;">&emsp;<i><b>I'm in the park with my friends.</b></i>&emsp;</u></div>
              <div style="display: flex;"><b style="padding-top: 6px;">1</b><textarea id="0" rows="2" >At 9.30 on Monday morning, </textarea></div>
              <div style="display: flex; margin-block: -20px"><b style="padding-top: 6px;">2</b><textarea id="1" rows="2" >On Sunday afternoon, </textarea></div>
              <div style="display: flex; margin-block: -20px"><b style="padding-top: 6px;">3</b><textarea id="2" rows="2" >At 4.00 in the morning, </textarea></div>
              <div style="display: flex; margin-block: -20px"><b style="padding-top: 6px;">4</b><textarea id="3" rows="2" >In July, </textarea></div>
              <div style="display: flex; margin-block: -20px"><b style="padding-top: 6px;">5</b><textarea id="4" rows="2" >At 8.00 in the evening, </textarea></div>
              <div style="display: flex; margin-block: -20px"><b style="padding-top: 6px;">6</b><textarea id="5" rows="2" >After school on Friday, </textarea></div>
            </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
