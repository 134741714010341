import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P60-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page60/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Write sentences using the affirmative (✔), negative(✘) or question(?) form of be.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>he / from / Istanbul ✔<br /><u style="text-decoration: dotted underline; color: grey"><i><b>He’s from Istanbul. </b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> I / a Star Wars fan ✘<br /> &emsp; #</div>
              <div><b>2 &emsp;</b> you / interested in / photography ?<br /> &emsp; #</div>
              <div><b>3 &emsp;</b> Murat / in this photo ✘<br /> &emsp; #</div>
              <div><b>4 &emsp;</b> it / Friday afternoon ✘<br /> &emsp; #</div>
              <div><b>5 &emsp;</b> your brother / fifteen ?<br /> &emsp; #</div>
              <div><b>6 &emsp;</b> Ann and Ella / twins ✔<br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "I'm not a Star Wars fan.",
          "Are you interested in photography?",
          "Murat isn't in this photo.",
          "It isn't Friday afternoon.",
          "Is your brother fifteen?",
          "Ann and Ella are twins.",
        ],
      },
    ],
  },

  2: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P60-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page60/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: "Choose the correct words.",
        color: "#4D3B98",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "His / Her",
          "Their / Our",
          " his / her",
          "your / my",
          "our / your",
          "Their / Your",
        ],
        answers: ["0-4", "1-4", "2-2", "5-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
              <div>
                <div>Our uncle is a doctor and <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">his</b> / <b>her</b> wife is a teacher.<div>
                <div><b>1.</b> My mum is interested in football. <b><input id='0' type='Circle' /></b> favourite player is Mesut Özil. </div>
                <div><b>2.</b> We’re Irish. <b><input id='1' type='Circle' /></b> home is in Dublin. </div>
                <div><b>3.</b> This is my brother and this is <b><input id='2' type='Circle' /></b> friend. </div>
                <div><b>4.</b> I’m a fan of the Harry Potter stories. They are <b><input id='3' type='Circle' /></b> favourite books.</div>
                <div><b>5.</b> ‘What’s <b><input id='4' type='Circle' /></b> name?’ ‘Caroline.’ </div>
                <div><b>6.</b> Those twin boys are in my class. <b><input id='5' type='Circle' /></b> house is next to the cinema. </div>
              </div>
          `,
    },
  },

  3: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P60-E3",
    component: T6,
    questionImage: [],
    inputSize: 90,
    exerciseKey: "img/FriendsPlus/Page60/E3/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: "left" },
    titleQuestion: [
      {
        num: "3",
        title: " Fill in the blanks with a suitable possessive pronoun.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> That pen belongs to me. That pen is  #</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> My wife and I own this building. This building is #</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> She has raised this cat herself. This cat is  #</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> You cannot take these books away. These books aren't #</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Those responsibilities lies with him. Those responsibilities are #</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> They bought that car yesterday. That car is  #</div></div>
              </div>
          `,
        answer: ["mine", "ours", "hers", "yours", "his ", "theirs"],
      },
    ],
  },

  4: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P60-E4",
    component: T6,
    questionImage: [],
    inputSize: 90,
    exerciseKey: "img/FriendsPlus/Page60/E4/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: "center" },
    titleQuestion: [
      {
        num: "4",
        title:
          " Fill in each blank with one suitable article (a, an, the) if necessary.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> There isn't # eel in this tank. </div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> I'd like # dozen eggs, please. </div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  #Earth rotates from the west to the east. </div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> All students must obey # rules at school.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> To some people, # spaghetti is delicious. </div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> In literature, we learn to write # essays. </div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> My friend, Laurent, is # talented soccer player.  </div></div>
              </div>
          `,
        answer: ["an", "a", "The", "the", "none", "none", "a "],
      },
    ],
  },

  5: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P60-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page60/E5/Key/answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: "Order the words to make sentences or questions.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>got / You’ve / mark / good / a <br /><u style="text-decoration: dotted underline; color: grey"><i><b>You’ve got a good mark. </b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> teacher / new / got / a / She’s  <br /> &emsp; #</div>
              <div><b>2 &emsp;</b> haven’t / a / got / I / notebook  <br /> &emsp; #</div>
              <div><b>3 &emsp;</b> history / They’ve / a / now / got / class  <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> got / we / Have / maths / morning / this ? <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> new / got / uniform / Jake / hasn’t / a <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> have / What / you / next / got ?  <br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "She's got a new teacher",
          "I haven't got a notebook.",
          "They've got a history class now.",
          "Have we got maths this morning?",
          "Jake hasn't got a new uniform.",
          "What have you got next?",
        ],
      },
    ],
  },

  6: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P60-E6",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page60/E6/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: "center" },
    hintBox: [
      {
        src: [
          "class",
          "exam",
          "fields",
          "girls",
          "music",
          "lab",
          "<s>room</s>",
        ],
        width: "558px",
        borderColor: "#4D3B98",
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title:
          " Complete the sentences with the correct form of there’s and there are and the words.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="width: 1000px; display: flex; justify-content: center; margin-block: 10px"><hintBox id='0'></hintbox></div>
                <div>
                  <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b>There's </b>&emsp;</u>  a nice poster in the music <u style="text-decoration: dotted underline;color: grey">&emsp;<b>room </b>&emsp;</u></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> # any boys at my school – only  #</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> # any new students in your #</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> We haven’t got a #  lesson today because #  time.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> # a difficult question in this maths #</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> # two big sports  # next to my school.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> # a new science #  in this block?</div></div>
              </div>
          `,
        answer: [
          "There aren't",
          "girls",
          "Are there",
          "class",
          "music",
          "there isn't",
          "There's",
          "exam",
          "There are",
          "fields",
          "Is there",
          "lab",
        ],
      },
    ],
  },
};

export default json;
