import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 5",
    id: "WB7-U5-P37-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20, textTransform: 'uppercase' },
    inputSize: 40,
    exerciseKey: "img/FriendsPlus/Page37/E1/Key/answerKey.png",
    maxLength: 1,
    textAlign: "center",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the text. Match paragraphs A–D with the headings below.",
        color: "#00a23b",
        star: 1
      },
    ],
    questions: [
      {
        title: `
            <div style="display: flex">
              <div><img src="img/FriendsPlus/Page37/E1/1.jpg"/></div>
              <div style="padding-top: 15px">
                <tr>
                  <td></td>
                  <td style=" border: 1px solid black; text-align: center; color: grey; font-weight: bold">B</td>
                  <td style="padding-left: 40px"> Basics </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> 1 </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Early career </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> 2 </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px">  Achievements </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> 3 </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Why he is still popular </td>
                </tr>
              </div>
          </div>
        `,
        answer: [
          "D",
          "C",
          "A",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "WB7-U5-P37-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page37/E2/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "born",
        "buy",
        "died",
        "eventually",
        "exciting",
        "first ",
        "history",
        "<s>love</s>",
        "sold",
      ],
      width: "420px",
      borderColor: "#00a23b",
    }],
    titleQuestion: [
      {
        num: "2",
        title:
          " Complete the text with the words.",
        color: "#00a23b",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page37/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 205px; left: 8px; "><input id='0' /></div>
              <div style=" position: absolute; top: 281px; left: 6px; "><input id='1' /></div>
              <div style=" position: absolute; top: 303px; left: 6px; "><input id='2' /></div>
              <div style=" position: absolute; top: 335px; left: 89px; "><input id='3' /></div>
              <div style=" position: absolute; top: 379px; left: 5px; "><input id='4' /></div>
              <div style=" position: absolute; top: 401px; left: 195px; "><input id='5' /></div>
              <div style=" position: absolute; top: 455px; left: 33px; "><input id='6' /></div>
              <div style=" position: absolute; top: 566px; left: 3px; "><input id='7' /></div>
            </div>
          </div>
        `,
        answer: [
          "exciting",
          "born",
          "died",
          "eventually",
          "history",
          "sold",
          "first",
          "buy",
        ]
      }
    ],
  },
  3: {
    unit: "Unit 5",
    id: "WB7-U5-P37-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page37/E3/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          " First write the questions for each paragraph title below in the correct place in the text.Then read the notes and complete the text.",
        color: "#00a23b",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page37/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 422px; left: 186px; "><input id='0' /></div>
              <div style=" position: absolute; top: 422px; left: 285px; "><input id='1' /></div>
              <div style=" position: absolute; top: 443px; left: -37px; "><input id='2' /></div>
              <div style=" position: absolute; top: 443px; left: 61px; "><input id='3' /></div>
              <div style=" position: absolute; top: 443px; left: 162px; "><input id='4' /></div>
              <div style=" position: absolute; top: 465px; left: -37px; "><input id='5' /></div>
              <div style=" position: absolute; top: 465px; left: 68px; "><input id='6' /></div>
              <div style=" position: absolute; top: 465px; left: 162px; "><input id='7' /></div>
              <div style=" position: absolute; top: 465px; left: 265px; "><input id='8' /></div>
              <div style=" position: absolute; top: 512px; left: -37px; "><input id='9' width='450px'/></div>
              <div style=" position: absolute; top: 534px; left: 273px; "><input id='15' /></div>
              <div style=" position: absolute; top: 555px; left: -32px; "><input id='16' /></div>
              <div style=" position: absolute; top: 555px; left: 67px; "><input id='17' /></div>
              <div style=" position: absolute; top: 555px; left: 164px; "><input id='18' /></div>
              <div style=" position: absolute; top: 598px; left: 293px; "><input id='19' /></div>
              <div style=" position: absolute; top: 619px; left: -33px; "><input id='20' /></div>
              <div style=" position: absolute; top: 619px; left: 66px; "><input id='21' /></div>
              <div style=" position: absolute; top: 619px; left: 167px; "><input id='22' /></div>
              <div style=" position: absolute; top: 619px; left: 258px; "><input id='23' /></div>
              <div style=" position: absolute; top: 646px; left: -32px; "><input id='24' width='450px'/></div>
              <div style=" position: absolute; top: 668px; left: 238px; "><input id='28' /></div>
              <div style=" position: absolute; top: 690px; left: -35px; "><input id='29' /></div>
              <div style=" position: absolute; top: 690px; left: 65px; "><input id='30' /></div>
              <div style=" position: absolute; top: 690px; left: 166px; "><input id='31' /></div>
              <div style=" position: absolute; top: 690px; left: 263px; "><input id='32' /></div>
              <div style=" position: absolute; top: 711px; left: -35px; "><input id='33' /></div>
              <div style=" position: absolute; top: 711px; left: 66px; "><input id='34' /></div>
              <div style=" position: absolute; top: 754px; left: 105px; "><input id='35' /></div>
              <div style=" position: absolute; top: 754px; left: 186px; "><input id='36' /></div>
              <div style=" position: absolute; top: 754px; left: 275px; "><input id='37' /></div>
              <div style=" position: absolute; top: 775px; left: -32px; "><input id='38' /></div>
              <div style=" position: absolute; top: 775px; left: 69px; "><input id='39' /></div>
              <div style=" position: absolute; top: 775px; left: 171px; "><input id='40' /></div>
              <div style=" position: absolute; top: 801px; left: -32px; "><input id='41' width='450px'/></div>
              <div style=" position: absolute; top: 844px; left: 0px; "><input id='47' /></div>
              <div style=" position: absolute; top: 844px; left: 82px; "><input id='48' /></div>
              <div style=" position: absolute; top: 844px; left: 175px; "><input id='49' /></div>
              <div style=" position: absolute; top: 844px; left: 278px; "><input id='50' /></div>
            </div>
          </div>
        `,
        answer: [
          "writer", "doctor", "journalist", "and", "politician",
          "in", "Scotland", "in", "1859",
          "When did he first start writing?",
          "the", "age", "of", "17",
          "called", "A", "Study", "in", "Scarlet",
          "What were his achievements?",
          "one", "of", "the", "most", "popular", "detective", "writers",
          "inventor", "of", "the", "modern", "detective", "story",
          "Why is he still popular today?",
          "mysteries", "are", "so", "exciting",
        ]
      }
    ],
  },
  4: {
    unit: "Unit 5",
    id: "WB7-U5-P37-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "  Think of a writer, film director, actor or singer from the past who is still popular today.Write an article about this person for a school magazine. Use the text in exercise 3 to help you",
        color: "#00a23b",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="11"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;