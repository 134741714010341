import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
  1: {
    unit: "Unit 6",
    id: "WB7-U6-P39-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page39/E1/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "<s>build</s>",
        "drink",
        "If",
        "if",
        "'ll",
        "survive",
        "will ",
        "Will",
        "won't",
      ],
      width: "530px",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img style="width:100%" src='img/FriendsPlus/Page39/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 43px; left: 327px; "><input id='0' /></div>
              <div style=" position: absolute; top: 146px; left: -24px; "><input id='1' /></div>
              <div style=" position: absolute; top: 135px; left: 343px; "><input id='2' /></div>
              <div style=" position: absolute; top: 236px; left: 305px; "><input id='3' /></div>
              <div style=" position: absolute; top: 355px; left: 42px; "><input id='4' /></div>
              <div style=" position: absolute; top: 442px; left: 130px; "><input id='5' /></div>
              <div style=" position: absolute; top: 430px; left: 340px; "><input id='6' /></div>
              <div style=" position: absolute; top: 530px; left:-10px; "><input id='7' /></div>
            </div>
          </div>
        `,
        answer: [
          `'ll`,
          `If`,
          `won't`,
          `will`,
          `survive`,
          `if`,
          `drink`,
          `Will`,
        ]
      }
    ],
  },
  2: {
    unit: "Unit 6",
    id: "WB7-U6-P39-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textAlign: "center",
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page39/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          " Complete the sentences with the correct form of the words.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>Mary will win the survival challenge if she <u style="text-decoration: dotted underline; color: grey"><i><b>makes</b></i>&emsp;</u> (you / ride) (make) a good shelter. <br/>If we see a dangerous animal, we  <u style="text-decoration: dotted underline; color: grey"><i><b>'ll run away</b></i>&emsp;</u>(run away)!</div>
              <div><b>1.</b> If we don’t listen, we # (not hear) our friends.</div>
              <div><b>2.</b>  They’ll be happy if they # (find) water</div>
              <div><b>3.</b> Will Ed make coffee if he # (get up) early? </div>
              <div><b>4.</b> If she # (see) a snake, she’ll climb a tree. </div>
              <div><b>5.</b> We # (not light) a fire if the weather is bad. </div>
              <div><b>6.</b> Where # (they / sit) if the sun is very hot? </div>
            </div>
        `,
        answer: [
          `won't hear `,
          `find`,
          `gets up`,
          `sees`,
          `won't light`,
          `will they sit`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "WB7-U6-P39-E3",
    component: T6,
    inputSize: 500,
    exerciseKey: "img/FriendsPlus/Page39/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Order the words to make sentences.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>doesn’t / he / now / go / <s>He’ll</s> / if / miss / bus / the</div>
                  <div>&emsp;He’ll <u style="text-decoration: dotted underline;">&emsp;<b>miss the bus if he doesn't go now.</b>&emsp;</u></div>
                  <div><b>1. </b> hot / <s>If</s> / it’s / I’ll / T-shirt / wear / a</div>
                  <div>&emsp;If #</div>
                  <div><b>2. </b> run / <s>We’ll</s> / catch / we / if / train / the</div>
                  <div>&emsp;We’ll #</div>
                  <div><b>3. </b>won’t / <s>They</s> / football / play / rains / it / if</div>
                  <div>&emsp;They #</div>
                  <div><b>4. </b> don’t / you / <s>If</s> / eat / feel / you’ll / hungry</div>
                  <div>&emsp;If #</div>
                  <div><b>5. </b> will / do / they / <s>What</s> / cold / very / if / it’s / ?</div>
                  <div>&emsp;What #</div>
                </div>
            `,
        answer: [
          ` it's hot, I'll wear a T-shirt. `,
          ` catch the train if we run.`,
          ` won't play football if it rains.`,
          ` you don't eat, you'll feel hungry.`,
          ` will they do if it's very cold?`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "WB7-U6-P39-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    inputSize: 140,
    exerciseKey: "img/FriendsPlus/Page39/E4/Key/answerKey.png",
    maxLength: 20,
    textAlign: "center",
    titleQuestion: [
      {
        num: "4",
        title:
          "  Complete the email with the correct form of the verbs.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page39/E4/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 75px; left: 42px; font-size: 22px">
                Hi Layla,</br>
                How are you? I can’t wait for the picnic at the </br>
                beach on Saturday! I think we’ll have a lot to </br>
                eat. I’ve got some crisps and drinks, and if I</br>
                <b style="color: grey">get up</b> (get up) early on Saturday, I</br> <sup>1</sup> # (bake) some cakes, too.</br>
                What <sup>2</sup> # (we / do) if it <sup>3</sup> #</br>
                (rain) on Saturday? Perhaps we could have an </br>
                indoor picnic at my house! If I <sup>4</sup> #</br>
                (ask) my mum nicely, perhaps she <sup>5</sup> #</br>
                (say) yes!</br>
                I think there will be you, me, Maya and Rachel. </br>
                But I spoke to Maya earlier and she’s ill. If she </br><sup>6</sup> # (not feel) well on Saturday, she </br><sup>7</sup> # (not come).</br>
                Love </br>
                Emma</br>
              </div>
            </div>
          </div>
        `,
        answer: [
          `'ll bake `, `will we do`, `rains`, `ask`,
          `'ll say `, `doesn't feel `, `won't come`,
        ]
      }
    ],
  },
  5: {
    unit: "Unit 6",
    id: "WB7-U6-P39-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the sentences with your own ideas.Use the first conditional.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div>If we don’t get up early, <span style="color: grey; font-weight: bold">we won’t arrive at the airport on time.</span></div>
          <div>
            <div style="display: flex"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>1</b></div><div><textarea id="0" rows="2"> If I don’t tidy my room,   </textarea></div></div>
            <div style="display: flex"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>2</b></div><div><textarea id="1" rows="2"> Our teacher won’t be happy if  </textarea></div></div>
            <div style="display: flex"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>3</b></div><div><textarea id="2" rows="2"> If I work hard at school, </textarea></div></div>
            <div style="display: flex"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>4</b></div><div><textarea id="3" rows="2"> We won’t go to the park if </textarea></div></div>
            <div style="display: flex"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>5</b></div><div><textarea id="4" rows="2"> If I see my favourite singer on TV, </textarea></div></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;
