import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB7-U5-P36-E1',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page36/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: ' Read the text. Choose the correct answer.',
        color: '#dc2c39',
        star: 1
      }
    ],
    titleImage_2: 'img/FriendsPlus/Page36/E1/1.jpg',
    imgSize: '80%',
    isQuestionVertical: true,
    isQuestionVerticalToImage: true,
    questions: [
      {
        title: `Which sentence might Nelson Dellis say?`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "‘Training your brain is difficult – not everybody can do it.’" },
          { image: "", isCorrect: true, right: "", text: "‘You can become a memory champion if you work hard.’" },
        ],
        no: 1,
        // title: '',
        type: '',
      },
    ]
  },

  2: {
    unit: "Unit 5",
    id: "WB7-U5-P36-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page36/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          " Read the text again. Choose the correct words.",
        color: "#dc2c39",
        star: 2
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "Australian / American / British", //0
          "body / brain / body_and_brain.", //1
          "parents / grandparents / friends ", //0
          "a_few / some / all ", //1
          "buy / read / study ", //0
        ],
        answers: ['0-4', '1-4', '2-4', '3-8', '4-0'],
        initialValue: [],
      },
      Layout: `
              <div style="font-size: 20px">
                <div><img style="width: 70%" src="img/FriendsPlus/Page36/E1/1.jpg"/></div>
                <div>Nelson Dellis has a <b>good</b> / <b> poor / </b><b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">billiant</b>memory.<div>
                <div><b>1.</b> Dellis was the winner of the <b><input id='0' type='Circle' /></b> championships. </div>
                <div><b>2.</b> Dellis trains his <b><input id='1' type='Circle' /></b></div>
                <div><b>3.</b> One of Dellis’s <b><input id='2' type='Circle' /></b> had memory problems.</div>
                <div><b>4.</b>  Dellis thinks <b><input id='3' type='Circle' /></b> people can become memory experts. </div>
                <div><b>5.</b> There is an example of how to remember things you need to <b><input id='4' type='Circle' /></b>.</div>
              </div>
          `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "WB7-U5-P36-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 20 },
    inputSize: 105,
    exerciseKey: "img/FriendsPlus/Page36/E3/Key/answerKey.png",
    maxLength: 1,

    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          " Read the text again and write <i>true</i> or <i>false</i>.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div style="font-size: 20px">
          <div><img style="width: 69%" src="img/FriendsPlus/Page36/E1/1.jpg"/></div>
          <div>
            <div>Dellis was second in the championships. <b style="color: grey"><i> false </i></b></div>
            <div><b>1.</b> Dellis can memorise a pack of playing cards in one minute and three seconds.  <input id='0' /></div>
            <div><b>2.</b> All the people at the championships did memory training.   <input id='1' /></div>
            <div><b>3.</b> Dellis was born in the United States.   <input id='2' /></div>
            <div><b>4.</b> Dellis thinks that he’s special.   <input id='3' /></div>
            <div><b>5.</b> A few memory experts use images to help them.     <input id='4' /></div>
          </div>
			  </div>
        `,
        answer: ["true", "true", "false", "false", "false"],
      },
    ],
  },

  4: {
    unit: "Unit 5",
    id: "WB7-U5-P36-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { fontSize: 23 },
    inputSize: 800,
    exerciseKey: "img/FriendsPlus/Page36/E4/Key/answerKey.png",

    titleQuestion: [
      {
        num: "4",
        title:
          "Answer the questions. Write complete sentences.",
        color: "#d01435",
        star: 3
      },
    ],
    questions: [
      {
        title: `
        <div><img src='img/FriendsPlus/Page36/E1/1.jpg'/></div>
              <div>
                <div>How many records did Nelson Dellis break? </div>
                <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b>He broke two</b>&emsp;</u></div>
                <div><b>1.</b> How many numbers can Dellis remember in five minutes?  </div>
                <div>#</div>
                <div><b>2.</b> What is a very important part of Dellis’s work? </div>
                <div>#</div>
                <div><b>3.</b> How often does Dellis train his brain? </div>
                <div>#</div>
                <div><b>4.</b> Why did Dellis first become interested in brain training? </div>
                <div>#</div>
                <div><b>5.</b> What does Dellis say is the best way to remember things?  </div>
                <div>#</div>
              </div>
          `,
        answer: [
          " He can remember 248 numbers. ",
          " Training is a very important part of Dellis's work.",
          " He trains his brain every day.",
          " He first became interested because his grandmother had memory problems.",
          " The best way is to change the word or number into a picture.",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 5",
    id: "WB7-U5-P36-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 23 },
    hintBox: [{
      src: [
        "scrambled",
        "<s>soft - boiled</s> ",
        "hard - boiled ",
        "nutrients ",
        "reduces",
      ],
      borderColor: "#d01435",
      width: "100%"
    }],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page36/E5/Key/answerKey.png",
    textAlign: 'center',

    titleQuestion: [
      {
        num: "5",
        title:
          "<b style='color: #d01435;'>VOCABULARY PLUS </b> Complete the sentences with the words.",
        color: "#d01435",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div>The three-minute egg timer is for  <u style="text-decoration: dotted underline;">&emsp;<b>soft-boiled </b>&emsp;</u> eggs.</div>
                <div><b>1.</b> He tried to eat 20 # eggs for a bet.</div>
                <div><b>2.</b> Giving up smoking # the risk of heart disease.</div>
                <div><b>3.</b> I like a light lunch, perhaps # eggs and salmon. </div>
                <div><b>4.</b> A healthy diet should provide all your essential #.</div>
              </div>
          `,
        answer: [" hard-boiled", "reduces", "scrambled", "nutrients"],
      },
    ],
  },
}

export default json;
