import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: "Cumulative Review",
		id: "WB7-CR-P57-E1",
		component: T6,
		questionImage: [],
		inputSize: 280,
		exerciseKey: "img/FriendsPlus/Page57/E1/Key/answerKey.png",
		stylesTextInput: { marginInline: '10px', fontSize: 24 },

		titleQuestion: [
			{
				num: "1",
				title:
					"Look at the photo",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
								<div>What can you see? </div>
								<div>#</div>
								<div>Which country do you think they are from?</div>
								<div>#</div>
								<div>What was the last museum you went to?</div>
								<div>#</div>
                <div><img src='img/FriendsPlus/Page57/E1/1.jpg' /></div>
              </div>
          `,
				answer: [
					"Statues",
					"The Great Wall of China",
					"",
				],
			},
		],
	},
	2: {
		unit: "Cumulative Review",
		id: "WB7-CR-P57-E2",
		component: T6,
		questionImage: [],
		inputSize: 740,
		exerciseKey: "img/FriendsPlus/Page57/E2/Key/answerKey.png",
		stylesTextInput: { marginInline: '10px', fontSize: 24 },

		titleQuestion: [
			{
				num: "2",
				title:
					"Read the text. Who were the statues for?",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
								<div>#</div>
                <div style="background-color: rgb(227, 242, 249);margin: 10px; padding: 10px; width: 1000px; box-shadow: 5px 5px rgb(198, 197, 197)">
									I’ll never forget the day I saw the Terracotta
									Army statues at a museum when I was on holiday
									in China last year. <br/></br>
									It was a great day. First we followed our tour
									guide, Jing Mei, into the enormous museum. Then,
									she told us about the history of the statues. They are
									more than 2,200 years old! She said that Emperor
									Qin wanted an army to protect him when he was
									dead. When he died in 210 BC, they buried him with
									an army of 8,000 terracotta statues! <br/></br>
									The statues are the size of real people and
									their faces, hair and clothes are all different. Some
									of them are bravely holding swords and some of
									them are sitting on horses. However, some of them
									are now lying broken on the floor! Jing Mei said
									that when the men discovered the statues in the
									countryside in 1974, some of the statues were
									jumping over other statues! <br/></br>
									While Jing Mei was talking to us, we were
									quietly taking photos. Why don’t you have a look at
									them on my web page?
								</div>
              </div>
          `,
				answer: [
					"Emperor Qin",
				],
			},
		],
	},
	3: {
		unit: "Cumulative Review",
		id: "WB7-CR-P57-E3",
		component: T6,
		questionImage: [],
		inputSize: 740,
		exerciseKey: "img/FriendsPlus/Page57/E3/Key/answerKey.png",
		stylesTextInput: { marginInline: '10px', fontSize: 24 },

		titleQuestion: [
			{
				num: "3",
				title:
					"Read the text again. Correct the sentences.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
                <div style="background-color: rgb(227, 242, 249);margin: 10px; padding: 10px; width: 1000px; box-shadow: 5px 5px rgb(198, 197, 197)">
									I’ll never forget the day I saw the Terracotta
									Army statues at a museum when I was on holiday
									in China last year. <br/></br>
									It was a great day. First we followed our tour
									guide, Jing Mei, into the enormous museum. Then,
									she told us about the history of the statues. They are
									more than 2,200 years old! She said that Emperor
									Qin wanted an army to protect him when he was
									dead. When he died in 210 BC, they buried him with
									an army of 8,000 terracotta statues! <br/></br>
									The statues are the size of real people and
									their faces, hair and clothes are all different. Some
									of them are bravely holding swords and some of
									them are sitting on horses. However, some of them
									are now lying broken on the floor! Jing Mei said
									that when the men discovered the statues in the
									countryside in 1974, some of the statues were
									jumping over other statues! <br/></br>
									While Jing Mei was talking to us, we were
									quietly taking photos. Why don’t you have a look at
									them on my web page?
								</div>
								<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> The tour guide was called Qin.</div></div>
								<div>#</div>
								<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> The emperor died in 1974.</div></div>
								<div>#</div>
								<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> The warriors all look the same.</div></div>
								<div>#</div>
								<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Some soldiers are lying brokenon horses.</div></div>
								<div>#</div>
								<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> They were found in the city.</div></div>
								<div>#</div>
								<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> The writer was taking photos patiently in the museum.</div></div>
								<div>#</div>
              </div>
          `,
				answer: [
					"The tour guide was called Jing Mei.",
					"The emperor died in 210 BC.",
					"The warriors all look different.",
					"Some soldiers are lying broken on the floor.",
					"They were found in the countryside.",
					"The writer was taking photos quietly in the museum.",
				],
			},
		],
	},

	4: {
		unit: "Cumulative Review",
		id: 'WB7-CR-P57-E4',
		audio: "Audios/1-10 TA 7 Friends Plus Workbook.mp3",
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: "4",
				title:
					"<audioimage name='10'></audioimage> Lucie is showing Emrah some photos of her holiday. Listen to the conversation. Put the photos in order. There is one photo Emrah does not see.",
				color: "#4D3B98",
			},
		],
		selectStyle: { color: '#00ADFE', width: 66, textAlign: 'center', fontSize: 17 },
		selectOptionRandom: true,
		selectInputSize: 'large', // large, middle, small
		selectOptionValues: [
			"1",
			"2",
			"3",
			"No",
		],
		exerciseKey: 'img/FriendsPlus/Page57/E4/Key/answerKey.png',
		questionImage: [],
		questions: [
			{
				title: `
					<div style='display: flex; justify-content: center; width: 1100px; position: relative; '>
            <div><img src='img/FriendsPlus/Page57/E4/1.jpg' /></div>
            <div style=" position: absolute; top: 104px; left: 489px; "><select id='0' /></select></div>
						<div style=" position: absolute; top: 104px; left: 879px; "><select id='1' /></select></div>
						<div style=" position: absolute; top: 261px; left: 489px; "><select id='2' /></select></div>
						<div style=" position: absolute; top: 261px; left: 879px; "><select id='3' /></select></div>
					</div>
				`,
				answer: [
					"No",
					"1",
					"2",
					"3",
				],
			},
		],
	},

	5: {
		unit: "Cumulative Review",
		id: "WB7-CR-P57-E5",
		audio: "Audios/1-10 TA 7 Friends Plus Workbook.mp3",
		component: T6,
		questionImage: [],
		stylesTextInput: { fontSize: 24 },
		inputSize: 105,
		exerciseKey: "img/FriendsPlus/Page57/E5/Key/answerKey.png",
		maxLength: 1,

		textAlign: "center",
		titleQuestion: [
			{
				num: "5",
				title:
					"<audioimage name='10'></audioimage> Listen again and write true or false.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
        <div style="display: flex">
          <div>
            <div><b>1.</b> Emrah knew that Lucie went to China.  <input id='0' /></div>
            <div><b>2.</b> Emrah can easily see where Lucie stayed.<input id='1' /></div>
            <div><b>3.</b> All pandas are black and white.  <input id='2' /></div>
            <div><b>4.</b> Emrah’s favourite photo is of a red panda.   <input id='3' /></div>
            <div><b>5.</b> The place in the last photo is in Beijing.    <input id='4' /></div>
            <div><b>6.</b> You can see trees in the background.    <input id='5' /></div>
          </div>
			  </div>
        `,
				answer: ["false", "false", "false", "false", "false", "true"],
			},
		],
	},

	6: {
		unit: "Cumulative Review",
		id: "WB7-CR-P57-E6",
		component: T6,
		questionImage: [],
		inputSize: 125,
		exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "6",
				title:
					" A TASK Write an account of a visit to a museum, famous statue or historical monument (e.g.the Eiffel Tower, Nelson’s Column). Use sequencing and linking words.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
								<div><textarea id="0" rows="11"></textarea></div>
              </div>
          `,
				answer: [],
			},
		],
	},
	7: {
		unit: "Cumulative Review",
		id: "WB7-CR-P57-E7",
		component: T6,
		questionImage: [],
		inputSize: 125,
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "6",
				title:
					" B THINK AND PLAN",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; "> What is the museum, statue, etc.? Where is it? When did you go and who did you go with?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; "> What did you learn?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; "> What did it look like?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; "> Did you take photos? Where can your friendssee them?</div></div>
	              </div>
          `,
				answer: [],
			},
		],
	},
	8: {
		unit: "Cumulative Review",
		id: "WB7-CR-P57-E8",
		component: T6,
		questionImage: [],
		inputSize: 125,
		exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "6",
				title:
					" C WRITE Use the text in exercise 2 and the writing guide below to help you.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
								<div style="display: flex">
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; width: fit-content; height: fit-content; ">Paragraph 1:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic">I’ll never forget …</span></div>
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; width: fit-content; height: fit-content; ">Paragraph 2:	<br/><span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic; display: inline-block; height: 10px !important">First … Then … </span></div>
								</div>
								<div style="display: flex">
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; width: fit-content; height: fit-content; margin-top: -28px ">Paragraph 3:	<br/><span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic">The … are / were …</span></div>
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; width: fit-content; height: fit-content; ">Paragraph 4:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic; display: inline-block; height: 10px !important">While …</span></div>
								</div>
								<div><textarea id="2" rows="11"></textarea></div>
              </div>
          `,
				answer: [],
			},
		],
	},

	9: {
		unit: "Cumulative Review",
		id: "WB7-CR-P57-E9",
		component: T6,
		questionImage: [],
		inputSize: 125,
		exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		hideBtnFooter: true,

		internetSearch: {
			content: `
				<b> Did you know ...</b><br />
        <i>In 1949, some birds sat on the minute hand of the
				Big Ben clock and stopped time for five minutes!</i>
        <br />
        Find out when tourists can visit Buckingham Palace.
			`
		},
		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		questions: [
			{
				title: `
              <div>
                <internetsearch></internetsearch>
              </div>
          `,
				answer: [],
			},
		],
	},
}

export default json;