import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB7-U1-P11-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 85,
    exerciseKey: "img/FriendsPlus/Page11/E1/Key/answerKey.png",
    maxLength: 7,

    textAlign: "center",
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div><img src='img/FriendsPlus/Page11/E1/1.jpg' /></div>
            <div>
              <div style="position: absolute; top: 252px; left: 140px;"><input id='0' /></div>
              <div style="position: absolute; top: 252px; left: 320px;"><input id='1' /></div>
              <div style="position: absolute; top: 389px; left: 15px;"><input id='2' /></div>
              <div style="position: absolute; top: 416px; left: 151px;"><input id='3' /></div>
              <div style="position: absolute; top: 389px; left: 278px;"><input id='4' /></div>
              <div style="position: absolute; top: 416px; left: 422px;"><input id='5' /></div>
            </div>
          </div>
        `,
        answer: ["Does", "stay", "do", "does", "don't", "doesn't"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB7-U1-P11-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 70,
    exerciseKey: "img/FriendsPlus/Page11/E2/Key/answerKey.png",
    maxLength: 16,

    // textAlign: "center",
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div><img src='img/FriendsPlus/Page11/E2/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 337px; left: 140px; "><input id='0' /></div>
              <div style=" position: absolute; top: 400px; left: 140px; "><input id='1' width='165' background-color='transparent' /></div>
              <div style=" position: absolute; top: 449px; left: 140px; "><input id='2' /></div>
              <div style=" position: absolute; top: 480px; left: 140px; "><input id='3' width='165' background-color='transparent' /></div>
              <div style=" position: absolute; top: 529px; left: 140px; "><input id='4' /></div>
              <div style=" position: absolute; top: 592px; left: 140px; "><input id='5' width='165' background-color='transparent' /></div>
              <div style=" position: absolute; top: 640px; left: 140px; "><input id='6' /></div>
              <div style=" position: absolute; top: 703px; left: 140px; "><input id='7' width='165' background-color='transparent' /></div>
              <div style=" position: absolute; top: 752px; left: 140px; "><input id='8' /></div>
              <div style=" position: absolute; top: 783px; left: 140px; "><input id='9' width='165' background-color='transparent' /></div>
              <div style=" position: absolute; top: 832px; left: 140px; "><input id='10' /></div>
              <div style=" position: absolute; top: 863px; left: 140px; "><input id='11' width='165' background-color='transparent' /></div>
            </div>
          </div>
        `,
        answer: [
          "Does",
          "Yes, she does./No, she doesn't.",
          "Do",
          "Yes, I do./No, I don't.",
          "Does",
          "Yes, he does./Yes, she does./No, he doesn't./No, she doesn't.",
          "Do",
          "Yes, I do./No, I don't.",
          "Do",
          "Yes, I do./No, I don't.",
          "Do",
          "Yes, I do./No, I don't.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB7-U1-P11-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 300,
    exerciseKey: "img/FriendsPlus/Page11/E3/Key/answerKey.png",

    questions: [
      {
        title: `
          <div style="position: relative; font-size: 22 px">
            <div><img style="width:50%" src='img/FriendsPlus/Page11/E3/1.jpg' /></div>
            <div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Olivia</b></div> Hi, Poppy! <u style="text-decoration: dotted underline;">&emsp;<i><b>Where do you go</b></i>&emsp;</u> (where / you / go) after school on Tuesdays?</div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Poppy</b></div><div> I usually go to the sports centre in town.</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 130px;"><b>Olivia</b></div> <div><sup style="vertical-align: 1px;">1</sup> # (you / want) to come to the school book club with me this Tuesday? It’s really good!</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Poppy</b></div><div> <sup style="vertical-align: 1px;">2</sup> # (what / you / do) there?</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Olivia</b></div><div> We read and write stories. Then we sometimes make videos. It’s a lot of fun.</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Poppy</b></div><div> <sup style="vertical-align: 1px;">3</sup> # (Kate and Alisha / go)?</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Olivia</b></div><div> Yes, <sup style="vertical-align: 1px;">4</sup> #. They really enjoy it.</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Poppy</b></div><div> Great. <sup style="vertical-align: 1px;">5</sup> # (where / you / meet)?</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Olivia</b></div><div> We all meet in the playground.</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Poppy</b></div><div> <sup style="vertical-align: 1px;">6</sup> # (the club / start) at 3.45?</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Olivia</b></div><div> No, <sup style="vertical-align: 1px;">7</sup> #. It usually starts at 4.00.</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Poppy</b></div><div> And <sup style="vertical-align: 1px;">8</sup> # (what time / it / finish)?</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Olivia</b></div><div> At 5.30. Is that OK?</div></div>
              <div style=" display: flex; margin-block: 5px"><div style="display: inline-block; width: 110px;"><b>Poppy</b></div><div> Yes, great. See you on Tuesday.</div></div>
            </div>
          </div>
        `,
        answer: [
          "Do you want",
          "What do you do",
          "Do Kate and Alisha go?",
          "they do",
          "Where do you meet?",
          "Does the club start",
          "it doesn't",
          "what time does it finish?",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P11-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [{
      src: [
        "collect",
        "do",
        "enjoy",
        "go",
        "like",
        "play",
        "spend ",
        "study",
        "watch",
        "work",
      ],
      width: "100%",
      borderColor: "#77cedf",
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          "Imagine there is a new family in the home next to yours. There are three children in the family and one is the same age as you. Write questions to ask him or her. Use the verbs to help you.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div><textarea id="0" rows="11">Where do you go to school? </textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
