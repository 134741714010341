import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB7-U4-P27-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page27/E1/Key/answerKey.png",
    maxLength: 16,

    textAlign: "center",
    isHiddenCheck: true,
    hintBox: [{
      src: [
        "having",
        "listening",
        "playing ",
        "<s>was</s>",
        "wasn’t",
        "watching",
        "were",
        "weren’t",
      ],
      width: "65%",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img style="width: 65%" src='img/FriendsPlus/Page27/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 94px; left: 153px; "><input id='0' /></div>
              <div style=" position: absolute; top: 42px; left: 311px; "><input id='1' /></div>
              <div style=" position: absolute; top: 94px; left: 311px; "><input id='2' /></div>
              <div style=" position: absolute; top: 201px; left: 153px; "><input id='3' /></div>
              <div style=" position: absolute; top: 252px; left: 153px; "><input id='4' /></div>
              <div style=" position: absolute; top: 201px; left: 311px; "><input id='5' /></div>
              <div style=" position: absolute; top: 252px; left: 311px; "><input id='6' /></div>
            </div>
          </div>
        `,
        answer: ["were", "watching", "playing", "wasn't", "weren't", "having", "listening",]
      }
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB7-U3-P24-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 220,
    exerciseKey: "img/FriendsPlus/Page27/E2/Key/answerKey.png",

    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences using the affirmative or negative past continuous form of the verbs.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div> It was cold on holiday so we<u style="text-decoration: dotted underline;color: grey">&emsp;<b>weren't wearing</b>&emsp;</u> our summer clothes. (not wear)</div>
                <div><b>1.</b> This time last year, I # by car across Australia. (travel)</div>
                <div><b>2.</b> At 7:30, Kai # to school on his bike. He was on the bus. (not cycle)</div>
                <div><b>3.</b> In that old photo, Lola and Sophie # on the sofa. (sit)</div>
                <div><b>4.</b> I # in class this morning because I felt ill. (not study)</div>
                <div><b>5.</b> We # a mountain in that photo. (climb)  </div>
                <div><b>6.</b>  In this photo, you # at the camera. (not look)  </div>
              </div>
          `,
        answer: [
          "was travelling",
          "wasn't cycling",
          "were sitting",
          "wasn't studying",
          "were climbing",
          "weren't looking",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB7-U4-P27-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page27/E3/Key/answerKey.png",
    maxLength: 16,

    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          " Look at Holly’s diary. Then complete the text using the past continuous.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page27/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 408px; left: 109px; "><input id='0' /></div>
              <div style=" position: absolute; top: 488px; left: 187px; "><input id='1' /></div>
              <div style=" position: absolute; top: 515px; left: 137px; "><input id='2' /></div>
              <div style=" position: absolute; top: 620px; left: 109px; "><input id='3' /></div>
              <div style=" position: absolute; top: 673px; left: 169px; "><input id='4' /></div>
              <div style=" position: absolute; top: 725px; left: 99px; "><input id='5' /></div>
            </div>
          </div>
        `,
        answer: ["was playing", "was helping ", "was baking ", "were having ", "was walking", "were watching"]
      }
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB7-U4-P27-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          " What were you doing at these different times? Write sentences using the affirmative and negative past continuous.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div>At 7:00 on Saturday evening, <span style="color: grey; font-weight: bold">my friends and I were watching a funny film. We weren't playing a video game.</span></div>
          <div>
            <div style="display: flex;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>1</b></div><div><textarea id="0" rows="2"> At 6:30 on Tuesday morning, </textarea></div></div>
            <div style="display: flex; margin-top: -28px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>2</b></div><div><textarea id="1" rows="2"> On Friday afternoon at 3:00,</textarea></div></div>
            <div style="display: flex; margin-top: -28px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>3</b></div><div><textarea id="2" rows="2"> At 6:30 on Saturday evening,</textarea></div></div>
            <div style="display: flex; margin-top: -28px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>4</b></div><div><textarea id="3" rows="2"> On Sunday morning at 10:30,</textarea></div></div>
            <div style="display: flex; margin-top: -28px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>5</b></div><div><textarea id="4" rows="2"> At 5:00 on my birthday, </textarea></div></div>
            <div style="display: flex; margin-top: -28px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>6</b></div><div><textarea id="5" rows="2"> At 10:00 on the first morning of the school holidays, </textarea></div></div>
          </div>
        `,
        answer: [],
      },
    ],
  },


}

export default json;