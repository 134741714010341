import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Cumulative Review",
    id: "WB7-CR-P58-E1",
    component: T6,
    questionImage: [],
    inputSize: 280,
    exerciseKey: "img/FriendsPlus/Page58/E1/Key/answerKey.png",
    stylesTextInput: { marginInline: '10px', fontSize: 24 },

    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the photo",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
              <div>
								<div>What is the woman doing?</div>
								<div>#</div>
								<div>Where do you think she is?</div>
								<div>#</div>
								<div>Would you like to do this activity?</div>
								<div>#</div>
                <div><img src='img/FriendsPlus/Page58/E1/1.jpg' /></div>
              </div>
          `,
        answer: [
          "She’s cooking on a fire. ",
          "In the forest.",
          "",
        ],
      },
    ],
  },
  2: {
    unit: "Cumulative Review",
    id: "WB7-CR-P58-E2",
    component: T6,
    questionImage: [],
    inputSize: 740,
    exerciseKey: "img/FriendsPlus/Page58/E2/Key/answerKey.png",
    stylesTextInput: { marginInline: '10px', fontSize: 24 },

    titleQuestion: [
      {
        num: "2",
        title:
          "Read the blog about a survival camp. Who had a worse time than the others? ",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
              <div>
								<div>#</div>
                <div style="margin: 10px"><img style="width: 74%"src='img/FriendsPlus/Page58/E2/1.jpg' /></div>
              </div>
          `,
        answer: [
          "Anna ",
        ],
      },
    ],
  },
  3: {
    unit: "Cumulative Review",
    id: "WB7-CR-P58-E3",
    component: T6,
    questionImage: [],
    audio: "Audios/1-09 TA 7 Friends Plus Workbook.mp3",
    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page58/E3/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'right' },
    titleQuestion: [
      {
        num: "3",
        title:
          " Read the text again. Complete the sentences with the name of the person.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
              <div style="display: flex">
								<div><img style="width: 74%"src='img/FriendsPlus/Page58/E2/1.jpg' /></div>
                <div style="margin-left: -150px">
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> # learned how to find something to drink.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> # says someone else will bring something.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> # says what he / she didn’t like to eat.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> # didn’t do as much exercise as the others.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> # was the first person to write a post.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> # says one thing you aren’t allowed to take.</div></div>
              </div>
            </div>
          `,
        answer: [
          "Grace",
          "Tom",
          "Anna",
          "Tom",
          "Charlie ",
          "Charlie",
        ],
      },
    ],
  },

  4: {
    unit: "Cumulative Review",
    id: "WB7-CR-P58-E4",
    audio: "Audios/1-11 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page58/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='11'></audioimage> Grace is now talking to her friend Jack. Listen to the conversation.Which three words do they not mention?",
        color: "#4D3B98",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "mention / not_mention",
          "mention / not_mention",
          "mention / not_mention",
          "mention / not_mention",
          "mention / not_mention",
          "mention / not_mention",
          "mention / not_mention",
          "mention / not_mention",
        ],
        answers: ['2-4', '5-4', '7-4', '4-0', '6-0', '0-0', '1-0', '3-0'],
        initialValue: [],
      },
      Layout: `
              <div style="display: flex">
								<div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> <div style="width: 50px; margin-right: 50px">expert</div> <b style="margin-left: 30px"><input id='0' type='Circle' /></b></div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> <div style="width: 50px; margin-right: 50px">shelter</div> <b style="margin-left: 30px"><input id='1' type='Circle' /></b></div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> <div style="width: 50px; margin-right: 50px">champion</div> <b style="margin-left: 30px"><input id='2' type='Circle' /></b></div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> <div style="width: 50px; margin-right: 50px">knife</div> <b style="margin-left: 30px"><input id='3' type='Circle' /></b></div></div>
								</div>
                <div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> <div style="width: 50px; margin-right: 50px">compass</div> <b style="margin-left: 30px"><input id='4' type='Circle' /></b></div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> <div style="width: 50px; margin-right: 50px">mushroom</div> <b style="margin-left: 30px"><input id='5' type='Circle' /></b></div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> <div style="width: 50px; margin-right: 50px">map</div> <b style="margin-left: 30px"><input id='6' type='Circle' /></b></div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> <div style="width: 50px; margin-right: 50px">mirror</div> <b style="margin-left: 30px"><input id='7' type='Circle' /></b></div></div>
								</div>
              </div>
          `,
    },
  },

  5: {
    unit: "Unit 6",
    id: "WB7-U6-P58-E5",
    audio: "Audios/1-11 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 33,
    exerciseKey: "img/FriendsPlus/Page58/E5/Key/answerKey.png",
    maxLength: 1,

    isHiddenCheck: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "5",
        title:
          "<audioimage name='11'></audioimage> Listen again and put sentences a–e in the correct order.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div style="padding-top: 15px">
                <tr>
                  <td style="padding: 0px 30px;"><b> a </b></td>
                  <td style=" border: 1px solid black; width: 40px; height: 40px">#</td>
                  <td style="padding-left: 40px">Grace tells Jack what he’ll learn on the course. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> b </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Grace’s family likes watching Bear Grylls’s survival programmes. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> c </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Jack will think about doing the course. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> d </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px">Grace’s mum didn’t have a good time. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> e </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Jack doesn’t know what to do in an emergency.</td>
                </tr>
              </div>
            </div>
        `,
        answer: [
          "4",
          "2",
          "5",
          "1",
          "3",
        ],
      },
    ],
  },

  6: {
    unit: "Cumulative Review",
    id: "WB7-CR-P58-E6",
    component: T6,
    questionImage: [],
    inputSize: 125,
    exerciseKey: "img/FriendsPlus/Page58/E5/Key/answerKey.png",
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    stylesTextInput: { marginInline: '10px', fontSize: 24 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title:
          " A TASK Imagine you went on the survival course with Grace’s family.Write a post for their blog.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
              <div>
								<div><textarea id="0" rows="11"></textarea></div>
              </div>
          `,
        answer: [],
      },
    ],
  },
  7: {
    unit: "Cumulative Review",
    id: "WB7-CR-P58-E7",
    component: T6,
    questionImage: [],
    inputSize: 125,
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    stylesTextInput: { marginInline: '10px', fontSize: 24 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title:
          " B THINK AND PLAN",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; "> What was the weekend like?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; "> What was the best thing? What was the worst?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; "> What should people take with them?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; "> What mustn’t they forget?</div></div>
	            </div>
          `,
        answer: [],
      },
    ],
  },
  8: {
    unit: "Cumulative Review",
    id: "WB7-CR-P58-E8",
    component: T6,
    questionImage: [],
    inputSize: 125,
    exerciseKey: "img/FriendsPlus/Page58/E5/Key/answerKey.png",
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    stylesTextInput: { marginInline: '10px', fontSize: 24 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title:
          " C WRITE Use the text in exercise 2 and the writing guide.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
              <div>
								<div style="display: flex">
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1; height: fit-content; ">Paragraph 1:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic;">The weekend was …</span></div>
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1; height: fit-content">Paragraph 2:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic; display: inline-block; height: 10px !important">The best thing was … , but the worst …</span></div>
                </div>
                <div>
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1; width: fit-content; margin-top: -30px">Paragraph 3:	<br/><span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic; display: inline-block; height: 10px !important"> You should … , but you mustn’t …</span></div>
								</div>
								<div><textarea id="2" rows="11"></textarea></div>
              </div>
          `,
        answer: [],
      },
    ],
  },

  9: {
    unit: "Cumulative Review",
    id: "WB7-CR-P58-E9",
    component: T6,
    questionImage: [],
    inputSize: 125,
    exerciseKey: "img/FriendsPlus/Page58/E5/Key/answerKey.png",
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,

    internetSearch: {
      content: `
				<b> Did you know ...</b><br />
        <i>Our bodies can survive for up to a week without water!</i>
        <br />
        Find how long Randy Gardner survived without sleep in 1964.
			`
    },
    stylesTextInput: { marginInline: '10px', fontSize: 24 },
    questions: [
      {
        title: `
              <div>
                <internetsearch></internetsearch>
              </div>
          `,
        answer: [],
      },
    ],
  },
}

export default json;  