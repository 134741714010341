import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 7",
    id: "WB7-U7-P47-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 22 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page47/E1/Key/answerKey.png",
    maxLength: 16,
    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "am",
        "<s>Am</s>",
        "'m not",
        "are",
        "Are",
        "aren't",
        "is ",
        "Is",
        "isn't",
        "going",
      ],
      width: "558px",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page47/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 89px; left: 51px; "><input id='0' /></div>
              <div style=" position: absolute; top: 89px; left: 300px; "><input id='1' /></div>
              <div style=" position: absolute; top: 133px; left: 51px; "><input id='2' /></div>
              <div style=" position: absolute; top: 240px; left: 2px; "><input id='3' /></div>
              <div style=" position: absolute; top: 240px; left: 285px; "><input id='4' /></div>
              <div style=" position: absolute; top: 270px; left: 113px; "><input id='5' /></div>
              <div style=" position: absolute; top: 270px; left: 387px; "><input id='6' /></div>
              <div style=" position: absolute; top: 298px; left: 137px; "><input id='7' /></div>
              <div style=" position: absolute; top: 298px; left: 418px; "><input id='8' /></div>
            </div>
          </div>
        `,
        answer: [
          "Is",
          "going",
          "Are",
          "am",
          "'m not / am not",
          "is",
          "isn't / 's not / is not",
          "are",
          "aren't / 're not / are not",
        ]
      }
    ],
  },
  2: {
    unit: "Unit 7",
    id: "WB7-U7-P47-E2",
    component: T6,
    inputSize: 500,
    exerciseKey: "img/FriendsPlus/Page47/E2/Key/answerKey.png",

    titleQuestion: [
      {
        num: "2",
        title:
          "Order the words to make questions.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>going / we / Are / to / that / see / singer / famous ?</div>
                  <div><u style="text-decoration: dotted underline;">&emsp;<b>Are we going to see that famous singer?</b>&emsp;</u></div>
                  <div><b>1. </b> to / Is / he / the / going / guitar / play ?</div>
                  <div>&emsp; #</div>
                  <div><b>2. </b> buy / going / you / tickets / the / Are / to ?</div>
                  <div>&emsp; #</div>
                  <div><b>3. </b> we / take / Are / photos / going / to ?</div>
                  <div>&emsp; #</div>
                  <div><b>4. </b> Sarah / Is / to / going / at / be / concert / the ?</div>
                  <div>&emsp; #</div>
                  <div><b>5. </b> are / Where / they / going / sit / to ?</div>
                  <div>&emsp; #</div>
                  <div><b>6. </b> to / the / is / What / singer / going / sing ?</div>
                  <div>&emsp; #</div>
                </div>
            `,
        answer: [
          "Is he going to play the guitar? ",
          "Are you going to buy the tickets?",
          "Are we going to take photos?",
          "Is Sarah going to be at the concert?",
          "Where are they going to sit?",
          "What is the singer going to sing?",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "WB7-U7-P47-E3",
    component: T6,
    inputSize: 450,
    exerciseKey: "img/FriendsPlus/Page47/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Write questions with be going to and the verbs in brackets.Then write answers that are true for you.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div><u style="text-decoration: dotted underline;">&emsp;<b>Are you going to watch</b>&emsp;</u>(you / watch ) any music videos this evening? <u style="text-decoration: dotted underline;">&emsp;<b>Yes, I am.</b>&emsp;</u></div>
                  <div><b>1. </b> # (you / listen to) any music later? <br/>&emsp;#</div>
                  <div><b>2. </b> # (your best friend / come) to your home tomorrow? <br/>&emsp;#</div>
                  <div><b>3. </b> # (you / get up) at 7:30 on Saturday? <br/>&emsp;#</div>
                  <div><b>4. </b> # (your school friends / meet) you in town at the weekend?  <br/>&emsp;#</div>
                  <div><b>5. </b> # (your teacher / give) you some more homework tomorrow? <br/>&emsp;#</div>
                </div>
            `,
        answer: [
          "Are you going to listen to", "Yes, I am. / No, I'm not.",
          "Is your best friend going to come", "Yes, he  is. / No, he isn't./Yes, she is. / No, she isn't.",
          "Are you going to get up", "Yes, I am. / No, I'm not.",
          "Are your school friends going to meet", "Yes, they are. / No, they aren't.",
          "Is your teacher going to give", "Yes, he  is. / No, he isn't./Yes, she is. / No, she isn't.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "WB7-U7-P47-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Imagine you interview your favourite band or singer.Write interview questions using be going to.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <textarea id="0" rows="11">Is your new video going to be on YouTube?</textarea>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "WB7-U7-P47-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the dialogue using the present continuous form of the verbs.",
        color: "#69d2e4",
        star: 2
      },
    ],
    inputSize: 200,
    exerciseKey: "img/FriendsPlus/Page47/E5/Key/answerKey.png",
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Ali</b></div> <div>What <u style="text-decoration: dotted underline;">&emsp;<b>are you doing</b>&emsp;</u> (you / do) after school today? <sup>1</sup> # (you / play) football?</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Bill</b></div> <div>No, I’m not. I <sup>2</sup> # (practise) for the school talent show.</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Ali</b></div> <div>Oh yeah! What <sup>3</sup> # (you / do) in the show?</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Bill</b></div> <div> I <sup>4</sup> # (sing) and Tom <sup>5</sup> # (play) the keyboard. Have you got tickets?</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Ali</b></div> <div>No, I haven’t. I <sup>6</sup> # (not come) because my aunt and uncle <sup>7</sup> # (arrive) tomorrow from Australia and they <sup>8</sup> # (not stay) with us for very long.</div></div>
              <div style="display: flex"><div style="display: inline-block;margin-right: 40px"><b>Bill</b></div> <div>OK. Have a nice time with them!</div></div>
            </div>
          </div>
        `,
        answer: [
          "Are you playing",
          "'m practising / am practising",
          "are you doing",
          "'m singing / am singing",
          "is playing ",
          "'m not coming / am not coming",
          "are arriving",
          "aren't staying / are not staying / 're not staying",
        ],
      },
    ],
  },

}

export default json;