import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: "Unit 5",
    id: "WB7-U5-P35-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 22 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page35/E1/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "<s>bake</s>",
        "can",
        "Can",
        "can't",
        "can't ",
        "could",
        "could",
        "Could",
        "couldn't",
        "play",
      ],
      width: "550px",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page35/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 70px; left: 279px; "><input id='0' /></div>
              <div style=" position: absolute; top: 145px; left: -30px; "><input id='1' /></div>
              <div style=" position: absolute; top: 145px; left: 336px; "><input id='2' /></div>
              <div style=" position: absolute; top: 177px; left: 336px; "><input id='3' /></div>
              <div style=" position: absolute; top: 301px; left: 9px; "><input id='4' /></div>
              <div style=" position: absolute; top: 301px; left: 366px; "><input id='5' /></div>
              <div style=" position: absolute; top: 402px; left: -25px; "><input id='6' /></div>
              <div style=" position: absolute; top: 403px; left: 298px; "><input id='7' /></div>
              <div style=" position: absolute; top: 435px; left: 297px; "><input id='8' /></div>

            </div>
          </div>
        `,
        answer: [
          "can't",
          "Can",
          "can",
          "can't",
          "could",
          "play",
          "Could",
          "could",
          "couldn't",
        ]
      }
    ],
  },
  2: {
    unit: "Unit 5",
    id: "WB7-U5-P35-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page35/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Change the sentences into the affirmative(✔), negative(✘) <br/>or question(?) form.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>Inventors can’t make a lot of different things. ✔<br /><u style="text-decoration: dotted underline; color: grey"><i><b>Inventors can make a lot of different things.</b></i>&emsp;</u></div>
              <div><b>1.</b> Albert can speak five languages. <b>?</b> <br />#</div>
              <div><b>2.</b> I could read when I was three years old. ✘ <br />#</div>
              <div><b>3.</b> Tim and Steve can’t cook well. ✔<br />#</div>
              <div><b>4.</b> My dad couldn’t swim very fast last year. ✔<br />#</div>
              <div><b>5.</b> We can understand Italian. ✘<br />#</div>
              <div><b>6.</b> Kim could read when she was five. <b>?</b><br />#</div>
            </div>
        `,
        answer: [
          `Can Albert speak five languages?`,
          `I couldn't read when I was three years old.`,
          `Tim and Steve can cook well.`,
          `My dad could swim very fast last year.`,
          `We can't understand Italian.`,
          `Could Kim read when she was five?`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "WB7-U5-P35-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 210,
    exerciseKey: "img/FriendsPlus/Page35/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Write questions with <i>can</i> or <i>could</i>. Then write short answers <br/>that are true for you.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div><u style="text-decoration: dotted underline; color: grey"><i><b>Could you ride</b></i>&emsp;</u> (you / ride) a bike when you were six? <br/>
              <u style="text-decoration: dotted underline; color: grey"><i><b>Yes, I could.</b></i>&emsp;</u></div>
              <div><b>1.</b> #  (you / make) music videos? <br/>&emsp;# </div>
              <div><b>2.</b> # (you / swim) when you were nine? <br/>&emsp;# </div>
              <div><b>3.</b> # (you / play) chess when you were eight? <br/>&emsp;# </div>
            </div>
        `,
        answer: [
          "Can you make",
          "Yes, I can. / No, I can't.",
          "Could you swim",
          "Yes, I could. / No, I couldn't.",
          "Could you play",
          "Yes, I could. / No, I couldn't.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "WB7-U5-P35-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write sentences with <i>can / can't, could / couldn't</i> and some of the words in the table.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div style="text-align: center"><img style="width: 40%" src='img/FriendsPlus/Page35/E4/1.jpg' /></div>
          <div><textarea id="0" rows="7">I couldn’t play the guitar when I was young. </textarea></div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "WB7-U5-P35-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page35/E5/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "<s>far</s>",
        "fast",
        "many",
        "much",
        "often",
        "old",
      ],
      width: "100%",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "5",
        title:
          " Complete the quiz questions with the words.<br/>Then match questions 1–6 with answers a–f.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page35/E5/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 145px; left: 0px; "><input id='0' /></div>
              <div style=" position: absolute; top: 145px; left: 115px; "><input id='1' /></div>
              <div style=" position: absolute; top: 185px; left: 0px; "><input id='2' /></div>
              <div style=" position: absolute; top: 185px; left: 115px; "><input id='3' /></div>
              <div style=" position: absolute; top: 224px; left: 0px; "><input id='4' /></div>
              <div style=" position: absolute; top: 224px; left: 115px; "><input id='5' /></div>
              <div style=" position: absolute; top: 265px; left: 0px; "><input id='6' /></div>
              <div style=" position: absolute; top: 265px; left: 115px; "><input id='7' /></div>
              <div style=" position: absolute; top: 305px; left: 0px; "><input id='8' /></div>
              <div style=" position: absolute; top: 305px; left: 115px; "><input id='9' /></div>
            </div>
          </div>
        `,
        answer: [
          "f", "fast",
          "d", "old",
          "c", "many",
          "a", "much",
          "e", "often",
        ]
      }
    ],
  },

}

export default json;