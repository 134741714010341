import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P66-E1",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page66/E1/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    hintBox: [{
      src: [
        "do",
        "go",
        "know",
        "<s>make</s>",
        "play",
        "spend",
        "watch",
      ],
      width: "558px",
      borderColor: "#4D3B98",
    }],
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the sentences using the affirmative, negative or question form of will and one of the verbs in brackets.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>Mariam is in hospital. She <u style="text-decoration: dotted underline;color: grey">&emsp;<b> won't come </b>&emsp;</u>  on the school trip. (leave / come)</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">  I think the weather in the jungle # (have / be) hot again tomorrow.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">  Marek knows about survival skills. He # (build / help) a good shelter for us all.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  Charlie and Ted feel ill. They # (go / climb) the trees with us later.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  I’m sure they # (win / walk) that race in the desert. They’re very fast.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  You aren’t interested in survival so you # (read / enjoy) this survival TV programme.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">  What # you # (do / buy) when you leave school?</div></div>
              </div>
          `,
        answer: [
          "will be",
          "'ll build",
          "won't climb",
          " 'll win",
          " won't enjoy",
          " will", "do",
        ],
      },
    ],
  },

  2: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P66-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page66/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Choose the correct words.",
        color: "#4D3B98",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          " look / ’ll_look", " find / ’ll_find",
          " won’t_watch / don’t_watch", "feels / ’ll_feel ",
          " will_be / is", "buy / ’ll_buy",
          "Will / Do", "light / ’ll_light",
          "’re / ’ll_be", "win / ’ll_win",
          "buy / will buy", "do / will",
          "Will / Do", " find / ’ll_find",
          "doesn’t_bring / won’t_bring ", "’s / ’ll_be",
        ],
        answers: ['8-0', '0-6', '1-2', '2-2', '3-0', '4-6', '5-4', '6-0', '7-0', '9-4', '10-0', '11-4', '12-0', '13-2', '14-0', '15-4'],
        initialValue: [],
      },
      Layout: `
              <div>
                <div>If we  <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">don’t take</b><b>/ won’t take</b> a water bottle, <b>are / </b> <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">’ll be</b>thristy<div>
                <div><b>1.</b>  I <b> <input id='0' type='Circle' /></b> for some food if you <b> <input id='1' type='Circle' /></b> some drinking water. </div>
                <div><b>2.</b> She <b> <input id='2' type='Circle' /></b> that survival film if she <b> <input id='3' type='Circle' /></b> tired. </div>
                <div><b>3.</b> If it <b> <input id='4' type='Circle' /></b> very hot on holiday, they <b> <input id='5' type='Circle' /></b> some cool clothes. </div>
                <div><b>4.</b> <b> <input id='6' type='Circle' /></b> you make dinner if I <b> <input id='7' type='Circle' /></b> a fire?</div>
                <div><b>5.</b> If you<b> <input id='8' type='Circle' /></b> patient and determined, you <b> <input id='9' type='Circle' /></b> the Desert Challenge.</div>
                <div><b>6.</b> If we <b> <input id='10' type='Circle' /></b> a tent, <b> <input id='11' type='Circle' /></b> you come camping with us?</div>
                <div><b>7.</b> <b> <input id='12' type='Circle' /></b> they build a shelter if we <b> <input id='13' type='Circle' /></b> some big branches?</div>
                <div><b>8.</b> If she <b> <input id='14' type='Circle' /></b> a sleeping bag, she <b> <input id='15' type='Circle' /></b> cold at night.</div>
              </div>
          `,
    },
  },

  3: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P66-E3",
    component: T6,
    questionImage: [],
    inputSize: 140,
    exerciseKey: "img/FriendsPlus/Page66/E3/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    hintBox: [{
      src: [
        "eat",
        "feel",
        "not find",
        "listen to",
        "<s>make</s>",
        "see",
        "not swim",
      ],
      width: "558px",
      borderColor: "#4D3B98",
    }],
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the sentences using the correct form of the verbs.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="width: 1000px; display: flex; justify-content: center; margin-block: 10px"><hintBox id='0'></hintbox></div>
                <div>
                  <div>If she <u style="text-decoration: dotted underline;color: grey">&emsp;<b>makes</b>&emsp;</u>a noise, the tiger will hear us.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> If he # any animals, he’ll stay still.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> They won’t survive if they # water.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> If we have some free time, we # the radio.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> I’ll wear sandals on the trip if I # hot.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> If we go near that dangerous river, I # in it.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> She # the food if she gets hungry.</div></div>
              </div>
          `,
        answer: [
          "sees",
          "don't find",
          "'ll listen to",
          "feel",
          "won't swim ",
          "'ll eat",
        ],
      },
    ],
  },

  4: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P66-E4",
    component: T6,
    questionImage: [],
    inputSize: 90,
    exerciseKey: "img/FriendsPlus/Page66/E4/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the sentences with should or must.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>San Francisco looks like an interesting city. Maybe we <u style="text-decoration: dotted underline;color: grey">&emsp;<b>should</b>&emsp;</u> go there on holiday.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> We # be at the airport by 9:45, or we’ll miss the plane.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> It’s quite warm. I think you # wear a T-shirt.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> That girl is very ill. She # go to the hospital.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> This ice cream is nice. Perhaps you # try it.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> You # eat lots of fruit and vegetables. It’s always a good idea.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> This football match is very important for our team. We # win it!</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> You # go to the new art gallery. You’ll enjoy it.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; ">  You # always show your passport at the airport. It’s the rule.</div></div>
              </div>
          `,
        answer: [
          "must",
          "should",
          "must",
          "should",
          "should",
          "must",
          "should",
          "must",
        ],
      },
    ],
  },

  5: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P66-E5",
    component: T6,
    questionImage: [],
    inputSize: 125,
    exerciseKey: "img/FriendsPlus/Page66/E5/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the text with should, shouldn’t, must or mustn’t.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="background-color: rgb(228, 227, 241);margin: 10px; padding: 10px; box-shadow: 5px 5px 5px rgb(198, 197, 197)">
                  <b>Information about the school</b></br>
                  <div style="margin-bottom: 15px">You <u style="text-decoration: dotted underline;color: grey">&emsp;<b>musn't</b>&emsp;</u> run in the school. It’s very dangerous.</div>
                  <div>Students <sup>1</sup> # eat chewing gum in class. This </div>
                  <div style="margin-bottom: 15px">is forbidden.</div>
                  <div>You <sup>2</sup> # always wear a uniform. You’ll need to </div>
                  <div style="margin-bottom: 15px">go home if you wear jeans.</div>
                  <div>If possible, all students <sup>3</sup> # try to do two hours </div>
                  <div style="margin-bottom: 15px">of homework every evening.</div>
                  <div>Students <sup>4</sup> # arrive later than 8:30 in the </div>
                  <div style="margin-bottom: 15px">morning. This is very important.</div>
                  <div>You <sup>5</sup> # have unhealthy food for lunch. It’s </div>
                  <div style="margin-bottom: 15px">better to have more healthy food.</div>
                  <div>You <sup>6</sup> # walk or take the bus to school if you </div>
                  <div>can. It’s good for the environment.</div>

								</div>
          `,
        answer: [
          "mustn't",
          "must",
          "should",
          "mustn't",
          "shouldn't",
          "should",
        ],
      },
    ],
  },
}

export default json;